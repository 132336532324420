import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
import {
  departments,
  locations,
  onboarding,
  rolesAndPermission,
} from '../components/OnBoarding/Organization/constants/onboarding.constants';
import { LoadingStatus } from '../constants/common';

export const decodeJWT = (token) => {
  const decodeToken = jwtDecode(token);
  return decodeToken;
};

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorageItem = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const sortRolesArray = (roles) => {
  return roles.sort((a, b) => {
    return a.list_order - b.list_order;
  });
};

export const getCommaSeparatedStringFromList = (list) =>
  list?.map((item) => item?.name).join(', ');

export const showToast = (message, theme) => {
  toast[theme](message, {
    position: toast.POSITION.TOP_CENTER,
  });
};

export const formatCurrency = (
  value,
  percisionNumber = 2,
  format = 'en-SG'
) => {
  return value.toLocaleString(format, {
    style: 'decimal',
    minimumFractionDigits: percisionNumber,
    maximumFractionDigits: percisionNumber,
  });
};

export const allowedKeys = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '.',
  'Backspace',
  'Enter',
  'Tab',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  'Delete',
  'Shift',
  'Control',
];

export const preventKeyPress = (event) => {
  if (!allowedKeys.includes(event.key)) {
    event.preventDefault();
  }
};
