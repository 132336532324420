import { clone } from 'lodash';
import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import {
  GET_COMPANY_INFO_FAILURE, GET_COMPANY_INFO_REQUEST, GET_COMPANY_INFO_SUCCESS,
  POST_COMPANY_INFO_REQUEST, POST_COMPANY_INFO_SUCCESS, POST_COMPANY_INFO_FAILURE,
  UPDATE_COMPANY_INFO_REQUEST, UPDATE_COMPANY_INFO_SUCCESS, UPDATE_COMPANY_INFO_FAILURE,
  GET_ROLES_LIST_REQUEST, GET_ROLES_LIST_SUCCESS, GET_ROLES_LIST_FAILURE, FETCH_COMPANY_ONBOARDING_PERMISSIONS_REQUEST,
  FETCH_COMPANY_ONBOARDING_PERMISSIONS_SUCCESS, FETCH_COMPANY_ONBOARDING_PERMISSIONS_FAILURE, PUBLISH_COMPANY_INFO_REQUEST,
  PUBLISH_COMPANY_INFO_SUCCESS, PUBLISH_COMPANY_INFO_FAILURE, RESET_ONBOARDING_STATE,
  GET_DEPARTMENT_INFO_FAILURE, GET_DEPARTMENT_INFO_REQUEST, GET_DEPARTMENT_INFO_SUCCESS,
  GET_SUB_DEPARTMENT_INFO_FAILURE, GET_SUB_DEPARTMENT_INFO_REQUEST, GET_SUB_DEPARTMENT_INFO_SUCCESS,
  POST_DEPARTMENT_SAVE_FAILURE, POST_DEPARTMENT_SAVE_REQUEST, POST_DEPARTMENT_SAVE_SUCCESS,
  GET_LOCATION_INFO_FAILURE, GET_LOCATION_INFO_SUCCESS, GET_LOCATION_INFO_REQUEST,
  POST_LOCATION_SAVE_FAILURE, POST_LOCATION_SAVE_REQUEST, POST_LOCATION_SAVE_SUCCESS,
  FETCH_EMPLOYEE_INFO_FAILURE, FETCH_EMPLOYEE_INFO_REQUEST, FETCH_EMPLOYEE_INFO_SUCCESS,
  UPDATE_DEPARTMENT_INFO_FAILURE, UPDATE_DEPARTMENT_INFO_REQUEST, UPDATE_DEPARTMENT_INFO_SUCCESS,
  GET_ALL_USERS_REQUEST, GET_ALL_USERS_SUCCESS, GET_ALL_USERS_FAILURE,
  DELETE_DEPARTMENT_FAILURE, DELETE_DEPARTMENT_REQUEST, DELETE_DEPARTMENT_SUCCESS,
  UPDATE_LOCATION_FAILURE, UPDATE_LOCATION_REQUEST, UPDATE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE, DELETE_LOCATION_REQUEST, DELETE_LOCATION_SUCCESS, CREATE_ROLE_REQUEST, CREATE_ROLE_SUCCESS, CREATE_ROLE_FAILURE,
  GET_DESIGNATION_FAILURE, GET_DESIGNATION_REQUEST, GET_DESIGNATION_SUCCESS, POST_DESIGNATION_FAILURE, POST_DESIGNATION_REQUEST, POST_DESIGNATION_SUCCESS,
  UPDATE_DESIGNATION_FAILURE, UPDATE_DESIGNATION_REQUEST, UPDATE_DESIGNATION_SUCCESS, DELETE_DESIGNATION_FAILURE, DELETE_DESIGNATION_REQUEST, DELETE_DESIGNATION_SUCCESS, FETCH_LOGO_IMAGE_FAILURE, FETCH_LOGO_IMAGE_REQUEST, FETCH_LOGO_IMAGE_SUCCESS
} from './actionTypes';


const initialState = {
  companyInfo: null,
  requestProcessing: {
    [APIRequestStatus.GET_COMPANY_INFO]: LoadingStatus.Loading,
    [APIRequestStatus.POST_COMPANY_INFO]: LoadingStatus.Loading,
    [APIRequestStatus.PUBLISH_COMPANY_INFO]: LoadingStatus.Loading,
    [APIRequestStatus.UPDATE_COMPANY_INFO]: LoadingStatus.Loading,
    [APIRequestStatus.GET_DEPARTMENT_INFO]: LoadingStatus.Loading,
    [APIRequestStatus.UPDATE_DEPARTMENT_INFO]: LoadingStatus.Loading,
    [APIRequestStatus.POST_DEPARTMENT_SAVE]: LoadingStatus.Loading,
    [APIRequestStatus.GET_LOCATION_INFO]: LoadingStatus.Loading,
    [APIRequestStatus.POST_LOCATION_INFO]: LoadingStatus.Loading,
    [APIRequestStatus.FETCH_EMPLOYEE_INFO]: LoadingStatus.Loading,
    [APIRequestStatus.GET_ROLES_LIST]: LoadingStatus.Loading,
    [APIRequestStatus.FETCH_PERMISSIONS]: LoadingStatus.Loading,
    [APIRequestStatus.DELETE_DEPARTMENT]: LoadingStatus.Loading,
    [APIRequestStatus.DELETE_LOCATION]: LoadingStatus.Loading,
    [APIRequestStatus.UPDATE_LOCATION]: LoadingStatus.Loading,
    [APIRequestStatus.CREATE_ROLE]: LoadingStatus.Loading,
    [APIRequestStatus.GET_DESIGNATION]: LoadingStatus.Loading,
    [APIRequestStatus.POST_DESIGNATION]: LoadingStatus.Loading,
    [APIRequestStatus.UPDATE_DESIGNATION]: LoadingStatus.Loading,
    [APIRequestStatus.DELETE_DESIGNATION]: LoadingStatus.Loading,
    [APIRequestStatus.FETCH_LOGO]: LoadingStatus.Loading,
  },
  rolesList: [],
  permissions: null,
  locations: [],
  employees: [],
  departments: [],
  users: [],
  designations: [],
  subDepartments:[],
  logoImage: null,
  userIdPropertiesMap: null,
  publicHoliday: []
};

const onBoardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_INFO_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_COMPANY_INFO]: LoadingStatus.Loading
        }
      };
    }

    case GET_COMPANY_INFO_SUCCESS: {
      const companyInfo = action.payload;
      return {
        ...state,
        companyInfo: companyInfo,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_COMPANY_INFO]: LoadingStatus.Success
        }
      };
    }

    case GET_COMPANY_INFO_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_COMPANY_INFO]: LoadingStatus.Failure
        }
      };
    }

    case GET_DEPARTMENT_INFO_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_DEPARTMENT_INFO]: LoadingStatus.Loading
        }
      };
    }

    case GET_DEPARTMENT_INFO_SUCCESS: {
      const departments = action.payload;
      departments.sort(function (a, b) {
        if (a.name < b.name)
          return -1;
        if (a.name > b.name)
          return 1;
        return 0;
      });
      return {
        ...state,
        departments: departments.map(({ manager, supervisor, ...rest }) => ({ ...rest, managerId: manager, supervisorId: supervisor })),
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_DEPARTMENT_INFO]: LoadingStatus.Success
        }
      };
    }

    case GET_DEPARTMENT_INFO_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_DEPARTMENT_INFO]: LoadingStatus.Failure
        }
      };
    }
    case GET_SUB_DEPARTMENT_INFO_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_SUB_DEPARTMENT_INFO]: LoadingStatus.Loading
        }
      };
    }

    case GET_SUB_DEPARTMENT_INFO_SUCCESS: {
      const subDepartments = action.payload;
      return {
        ...state,
        subDepartments: subDepartments,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_SUB_DEPARTMENT_INFO]: LoadingStatus.Success
        }
      };
    }

    case GET_SUB_DEPARTMENT_INFO_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_SUB_DEPARTMENT_INFO]: LoadingStatus.Failure
        }
      };
    }

    case POST_DEPARTMENT_SAVE_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_DEPARTMENT_SAVE]: LoadingStatus.Loading
        }

      };
    }

    case POST_DEPARTMENT_SAVE_SUCCESS: {
      const { res, name } = action.payload;
      return getStateOnPostDepartmentSuccess(state, res, name);
    }

    case POST_DEPARTMENT_SAVE_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_DEPARTMENT_SAVE]: LoadingStatus.Failure
        }
      };
    }



    case POST_COMPANY_INFO_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_COMPANY_INFO]: LoadingStatus.Loading
        }
      };
    }

    case POST_COMPANY_INFO_SUCCESS: {
      const { companyInfo, id } = action.payload;
      return getStateOnPostCompanyInfoSuccess(state, companyInfo, id);
    }

    case POST_COMPANY_INFO_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_COMPANY_INFO]: LoadingStatus.Failure
        }
      };
    }

    case UPDATE_COMPANY_INFO_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.UPDATE_COMPANY_INFO]: LoadingStatus.Loading
        }
      };
    }

    case UPDATE_COMPANY_INFO_SUCCESS: {
      const companyInfo = action.payload;
      return {
        ...state,
        companyInfo: companyInfo,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.UPDATE_COMPANY_INFO]: LoadingStatus.Success
        }
      };
    }

    case UPDATE_COMPANY_INFO_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ROLES_LIST]: LoadingStatus.Failure
        }
      };
    }

    case GET_ROLES_LIST_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ROLES_LIST]: LoadingStatus.Loading
        }
      };
    }

    case GET_ROLES_LIST_SUCCESS: {
      const rolesList = action.payload;
      return {
        ...state,
        rolesList: rolesList,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ROLES_LIST]: LoadingStatus.Success
        }
      };
    }

    case GET_ROLES_LIST_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ROLES_LIST]: LoadingStatus.Failure
        }
      };
    }

    case FETCH_COMPANY_ONBOARDING_PERMISSIONS_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_PERMISSIONS]: LoadingStatus.Loading
        }
      };
    }

    case FETCH_COMPANY_ONBOARDING_PERMISSIONS_SUCCESS: {
      const permissions = action.payload;
      return {
        ...state,
        permissions: permissions,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_PERMISSIONS]: LoadingStatus.Success
        }
      };
    }

    case FETCH_COMPANY_ONBOARDING_PERMISSIONS_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_PERMISSIONS]: LoadingStatus.Failure
        }
      };
    }

    case PUBLISH_COMPANY_INFO_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.PUBLISH_COMPANY_INFO]: LoadingStatus.Loading
        }
      };
    }

    case PUBLISH_COMPANY_INFO_SUCCESS: {
      return {
        ...state,
        companyInfo: { ...state.companyInfo, isPublished: true },
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.PUBLISH_COMPANY_INFO]: LoadingStatus.Success
        }
      };
    }

    case PUBLISH_COMPANY_INFO_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.PUBLISH_COMPANY_INFO]: LoadingStatus.Failure
        }
      };
    }

    case RESET_ONBOARDING_STATE: {
      return initialState;
    }

    case GET_LOCATION_INFO_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_LOCATION_INFO]: LoadingStatus.Loading
        }
      };
    }

    case GET_LOCATION_INFO_SUCCESS: {
      const locations = action.payload;
      return {
        ...state,
        locations: locations,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_LOCATION_INFO]: LoadingStatus.Success
        }
      };
    }

    case GET_LOCATION_INFO_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_LOCATION_INFO]: LoadingStatus.Failure
        }
      };
    }

    case POST_LOCATION_SAVE_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_LOCATION_INFO]: LoadingStatus.Loading
        }

      };
    }

    case POST_LOCATION_SAVE_SUCCESS: {
      const { res, name } = action.payload;
      return getStateOnPostLocationSuccess(state, res, name);
    }

    case POST_LOCATION_SAVE_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_LOCATION_INFO]: LoadingStatus.Failure
        }
      };
    }

    case FETCH_EMPLOYEE_INFO_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_EMPLOYEE_INFO]: LoadingStatus.Loading
        }
      };
    }

    case FETCH_EMPLOYEE_INFO_SUCCESS: {
      const employees = action.payload;
      return {
        ...state,
        employees,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_EMPLOYEE_INFO]: LoadingStatus.Success
        }
      };
    }

    case FETCH_EMPLOYEE_INFO_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_EMPLOYEE_INFO]: LoadingStatus.Failure
        }
      };
    }

    case UPDATE_DEPARTMENT_INFO_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.UPDATE_DEPARTMENT_INFO]: LoadingStatus.Loading
        }
      };
    }

    case UPDATE_DEPARTMENT_INFO_SUCCESS: {
      const { departmentData, index } = action.payload;
      return getStateOnUpdateDepartmentSuccess(state, departmentData, index);
    }



    case UPDATE_DEPARTMENT_INFO_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.UPDATE_DEPARTMENT_INFO]: LoadingStatus.Failure
        }
      };
    }

    case GET_ALL_USERS_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_USERS]: LoadingStatus.Loading
        }
      };
    }

    case GET_ALL_USERS_SUCCESS: {
      const users = action.payload;
      let userIdPropertiesMap = {};
      users?.forEach((item) => {Object.assign(userIdPropertiesMap, {[item.id]: {
        statusCode: item.employeeStatus.statusCode, 
        name: item.firstName}
      });});
      return {
        ...state,
        users: users,
        userIdPropertiesMap: userIdPropertiesMap,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_USERS]: LoadingStatus.Success
        }
      };
    }

    case GET_ALL_USERS_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_USERS]: LoadingStatus.Failure
        }
      };
    }

    case DELETE_DEPARTMENT_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.DELETE_DEPARTMENT]: LoadingStatus.Loading
        }
      };
    }

    case DELETE_DEPARTMENT_SUCCESS: {
      const index = action.payload;
      const newDepartments = state.departments;
      newDepartments.splice(index, 1);
      return {
        ...state,
        departments: newDepartments,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.DELETE_DEPARTMENT]: LoadingStatus.Success
        }
      };
    }

    case DELETE_DEPARTMENT_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.DELETE_DEPARTMENT]: LoadingStatus.Failure
        }
      };
    }

    case DELETE_LOCATION_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.DELETE_LOCATION]: LoadingStatus.Loading
        }
      };
    }

    case DELETE_LOCATION_SUCCESS: {
      const index = action.payload;
      const newLocations = state.locations;
      newLocations.splice(index, 1);
      return {
        ...state,
        locations: newLocations,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.DELETE_LOCATION]: LoadingStatus.Success
        }
      };
    }

    case DELETE_LOCATION_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.DELETE_LOCATION]: LoadingStatus.Failure
        }
      };
    }

    case UPDATE_LOCATION_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.UPDATE_LOCATION]: LoadingStatus.Loading
        }
      };
    }

    case UPDATE_LOCATION_SUCCESS: {
      const { name, index } = action.payload;
      return getStateOnUpdateLocationSuccess(state, name, index);
    }

    case UPDATE_LOCATION_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.UPDATE_LOCATION]: LoadingStatus.Failure
        }
      };
    }

    case CREATE_ROLE_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.CREATE_ROLE]: LoadingStatus.Loading
        }
      };
    }

    case CREATE_ROLE_SUCCESS: {
      const { id, name } = action.payload;

      return {
        ...state,
        rolesList: [...state.rolesList, { id: id, role_name: name }],
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.CREATE_ROLE]: LoadingStatus.Success
        }
      };
    }

    case CREATE_ROLE_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.CREATE_ROLE]: LoadingStatus.Failure
        }
      };
    }

    case GET_DESIGNATION_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_DESIGNATION]: LoadingStatus.Loading
        }
      };
    }

    case GET_DESIGNATION_SUCCESS: {
      const designations = action.payload;
      return {
        ...state,
        designations: designations,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_DESIGNATION]: LoadingStatus.Success
        }
      };
    }

    case GET_DESIGNATION_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_DESIGNATION]: LoadingStatus.Failure
        }
      };
    }

    case DELETE_DESIGNATION_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.DELETE_DESIGNATION]: LoadingStatus.Loading
        }
      };
    }

    case DELETE_DESIGNATION_SUCCESS: {
      const index = action.payload;
      const newDesignation = state.designations;
      newDesignation.splice(index, 1);
      return {
        ...state,
        designations: newDesignation,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.DELETE_DESIGNATION]: LoadingStatus.Success
        }
      };
    }

    case DELETE_DESIGNATION_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.DELETE_DESIGNATION]: LoadingStatus.Failure
        }
      };
    }

    case POST_DESIGNATION_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_DESIGNATION]: LoadingStatus.Loading
        }

      };
    }

    case POST_DESIGNATION_SUCCESS: {
      const { res, name } = action.payload;
      return getStateOnPostDesignationSuccess(state, res, name);
    }

    case POST_DESIGNATION_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_DESIGNATION]: LoadingStatus.Failure
        }
      };
    }

    case UPDATE_DESIGNATION_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.UPDATE_DESIGNATION]: LoadingStatus.Loading
        }
      };
    }

    case UPDATE_DESIGNATION_SUCCESS: {
      const { designationData, index } = action.payload;
      return getStateOnUpdateDesignationSuccess(state, designationData, index);
    }



    case UPDATE_DESIGNATION_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.UPDATE_DESIGNATION]: LoadingStatus.Failure
        }
      };
    }

    case FETCH_LOGO_IMAGE_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_LOGO]: LoadingStatus.Loading
        }
      };
    }

    case FETCH_LOGO_IMAGE_SUCCESS: {
      const logoImage = action.payload;
      const dates = logoImage?.publicHolidays?.map(item => item.calDate);
      return {
        ...state,
        logoImage: logoImage,
        publicHoliday: dates,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_LOGO]: LoadingStatus.Success
        }
      };
    }

    case FETCH_LOGO_IMAGE_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.FETCH_LOGO]: LoadingStatus.Failure
        }
      };
    }

    default:
      return state;
  }
};

const getStateOnPostDepartmentSuccess = (state, data, departmentName) => {
  const newState = clone(state);
  const newDept = newState.departments;
  const newDepartment = {
    companyId: null,
    id: data.id,
    managerId: null,
    managerName: '',
    name: departmentName,
    supervisorId: null,
    supervisorName: '',
    totalTeams: null
  };
  newDept.push(newDepartment);
  newState.departments = newDept;
  newState.requestProcessing[APIRequestStatus.POST_DEPARTMENT_SAVE] = LoadingStatus.Success;
  return newState;
};

const getStateOnPostLocationSuccess = (state, data, locationName) => {
  const newState = clone(state);
  const newLoc = newState.locations;
  const newLocation = {
    id: data.id,
    name: locationName,
    officeCount: null
  };
  newLoc.push(newLocation);
  newState.locations = newLoc;
  newState.requestProcessing[APIRequestStatus.POST_LOCATION_INFO] = LoadingStatus.Success;
  return newState;
};

const getStateOnUpdateDepartmentSuccess = (state, departmentData, index) => {
  const { managerId, supervisorId, name } = departmentData;
  const newState = clone(state);
  const newDepts = newState.departments;
  const employees = newState.employees;
  newDepts[index] = {
    ...newDepts[index],
    name: name,
    managerId: managerId,
    supervisorId: supervisorId,
    managerName: employees.find((emp) => emp.id === managerId)?.name,
    supervisorName: employees.find((emp) => emp.id === supervisorId)?.name,
  };
  newState.departments = newDepts;
  newState.requestProcessing[APIRequestStatus.UPDATE_DEPARTMENT_INFO] = LoadingStatus.Success;
  return newState;
};
const getStateOnPostCompanyInfoSuccess = (state, companyInfo, id) => {
  const newState = clone(state);
  newState.companyInfo = companyInfo;
  newState.companyInfo.id = id;
  newState.requestProcessing[APIRequestStatus.POST_COMPANY_INFO] = LoadingStatus.Success;
  return newState;
};

const getStateOnUpdateLocationSuccess = (state, name, index) => {
  const newState = clone(state);
  const newLocs = newState.locations;
  newLocs[index] = {
    ...newLocs[index],
    name: name,
  };
  newState.locations = newLocs;
  newState.requestProcessing[APIRequestStatus.UPDATE_LOCATION_INFO] = LoadingStatus.Success;
  return newState;
};

const getStateOnPostDesignationSuccess = (state, data, name) => {
  const newState = clone(state);
  const newDesignation = newState.designations;
  const newDesignations = {
    id: data.id,
    shortName: name,
    longName: data.longName,
    fkGrade: data.fkGrade
  };
  newDesignation.push(newDesignations);
  newState.designations = newDesignation;
  newState.requestProcessing[APIRequestStatus.POST_DESIGNATION] = LoadingStatus.Success;
  return newState;
};

const getStateOnUpdateDesignationSuccess = (state, designationData, index) => {
  const { shortName, longName, fkGrade } = designationData;
  const newState = clone(state);
  const newDesignation = newState.designations;
  newDesignation[index] = {
    ...newDesignation[index],
    shortName: shortName,
    longName: longName,
    fkGrade: fkGrade,
  };
  newState.designations = newDesignation;
  newState.requestProcessing[APIRequestStatus.UPDATE_DESIGNATION] = LoadingStatus.Success;
  return newState;
};

export default onBoardingReducer;
