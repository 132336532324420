import axios from 'axios';
import store from '../redux/store';
import { setAuth } from '../redux/main/action';
import { decodeJWT, setLocalStorage } from '../utils/common.util';

export default class API {
    static env = process.env;
    static token = `Bearer ${JSON.parse(localStorage.getItem('token'))}`;

    instance;
    header = {
      'Content-type': 'application/json',
      'client_id': null
    };

    async getValidToken (baseURL, tenant){
      const response = {token: null, refreshToken: null};
      const Token  = JSON.parse(localStorage.getItem('token'));
      const refreshToken = JSON.parse(localStorage.getItem('refreshToken'));
      // const Token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlcyI6W3siaWQiOjIsInJvbGVfbmFtZSI6InN1cGVyLXVzZXIifV0sImlkIjoxLCJlbWFpbCI6InNodWJoYW0ubEB2ZXhlY3V0aW9uLmNvbSIsImlhdCI6MTY5MDcwODAwNiwiZXhwIjoxNjkwNzExNjA2fQ.ewjFEIqZVOJ3We_EJ4vKJzOlHAYTuNAazDh0XXiBHW8';
      // validate token;
      if(Token && refreshToken ){
        const validToken = decodeJWT(Token);
        
        if(Date.now() < validToken.exp * 1000 ){
          // console.log('validToken success', validToken);
          response.token = Token;
          response.refreshToken = refreshToken;
          return response;
        }else{
          // console.log('validToken failer', validToken, Date.now());
          // Get the token with the refresh token
          try {
            // const res = await this.instance.post(baseURL+'/auth/refresh-token', {
            //   refresh_token: refreshToken,
            // });
            const res = await fetch(`${baseURL}/auth/refresh-token`,{
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'client_id': tenant
                // 'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: JSON.stringify({refresh_token: `${refreshToken}`})});
            const  resp = await res.json();
            const {token, refresh_token} = resp;
            console.log('resp', resp);
            response.token = token;
            response.refreshToken = refresh_token;
            if(resp.retry === false){
              setLocalStorage('sessionExpired', true);
            }
            if(token && refreshToken) {
              setLocalStorage('refreshToken' , refresh_token);
              setLocalStorage('token', token);
            }

          } catch (error) {
            this.logout();
            console.log('Error while generating the refresh token', error);
          }
          console.log('response', response);
          return response;
        }
      } else {
        return response;
      }
      
      
    }

    constructor(baseURL, header) {
      const hostname = window.location.hostname;
      const tenant = hostname.split('.')[0] === 'localhost'? process.env.REACT_APP_LOCALHOST_CLIENT_ID : hostname.split('.')[0];
        this.header.client_id = tenant;
        this.instance = axios.create({
          baseURL,
          headers: (header) ? header : this.header
        });

        const token  = store.getState().main?.token || JSON.parse(localStorage.getItem('token'));
        if (token) {
          // check for valid token
          
          // if not valid then validate with the refresh tokne and set that token into new request and in store.

          this.instance.interceptors.request.use(
            async (requestConfig) => {
              const {token} = await this.getValidToken(process.env.REACT_APP_BASE_API_LOCAL, tenant);
              // setLocalStorage('sessionExpired', true);
              const config = { ...requestConfig };
              config.headers.Authorization = `Bearer ${token}`;
              return config;
            },
            (error) => Promise.reject(error),
          );
        }

        const refreshToken = JSON.parse(localStorage.getItem('refreshToken'));
        this.instance.interceptors.response.use(
          (res) => {
            return res;
          },
          // async (err) => {
          //   console.log('token', store.getState().main);
          //   const originalConfig = err.config;
          //   console.log(originalConfig);
          //   if (err.response) {
          //     console.log('errorData', err.response?.data);
          //     const retry = err.response?.data?.retry === false ? false : true;
          //     if (err.response.status === 401 && retry) {
          //       try {
          //         const res = await this.instance.post('auth/refresh-token', {
          //           refresh_token: refreshToken,
          //         });
          //         const {token, refresh_token} = res.data.data;
          //         setLocalStorage('token', token);
          //         setLocalStorage('refreshToken' ,refreshToken);
          //         store.dispatch(setAuth({
          //           refreshToken: refresh_token,
          //           token: token,
          //         }));
          //         console.log('Store update');
          //         return this.instance(originalConfig);
          //       } catch (_error) {
          //         console.log('_error', _error);
          //         // this.logout();
          //         return Promise.reject(_error);
          //       }
          //     }
          //   }
          //   return Promise.reject(err);
          // }
        );


    }

    responsify(response) {
        if (response && response.status === 200 && response.data) {
            return response.data.data;
        }
        return null;
      }
    
    logout() {
      store.dispatch(setAuth(null));
      localStorage.clear();
      window.location.reload();
    }

}   
