import { Box, Button, Grid } from '@mui/material';
import React from 'react';

const BenefitConfigButtons = (props) => {
  const { setShowConfig, viewMode } = props;

  const button = [
    {
      name: 'Amount Config',
      onClick: () => setShowConfig('amount'),
      disable: viewMode
    },
    {
      name: 'Start Date Config',
      onClick: () => setShowConfig('startDate'),
      disable: viewMode
    },
    {
      name: 'Block Period Config',
      onClick: () => setShowConfig('blockPeriod'),
      disable: viewMode
    },
  ];
  return (
    <Box>
      <Grid container rowSpacing={3} columnSpacing={3}>
        {button.map((item, index) => {
          const { name, onClick, disable } = item;
          return (
            <Grid item md={4} key={index}>
              <Button
                variant="text"
                color="primary"
                sx={{ textTransform: 'none' }}
                onClick={onClick}
                disabled={disable}
              >
                {name}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default BenefitConfigButtons;
