import React from 'react';
import { Box, Typography } from '@mui/material';

export default function FieldView({
    labelText,
    labelValue,
    required,
    textTransform,
    ...rest
  }) {
  return (
    <Box>
      {labelText && (
        <Typography variant='body2' color={'neutral.dark60'}>
          {labelText}{' '}
          {required && (
            <Box color='accentSecondary.main' sx={{ display: 'inline-block' }}>
              *
            </Box>
          )}
        </Typography>
      )}
      {labelValue && (
        <Typography variant='body2' color={'neutral.dark100'} mt={1} sx={{textTransform: {textTransform}}}>
          {labelValue}
        </Typography>
      )}
    </Box>
  );
}
