import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { APIRequestStatus, ToastThemes, toastMessage } from '../../../../../../../constants/common';
import ActionHeader from '../../../../../../OnBoarding/Organization/ActionHeader/ActionHeader';
import FormEdit from '../../../../../../OnBoarding/common/Form/FormEdit';
import { useMemo } from 'react';
import { EmpType, getMandatoryFields, getValidationErrors } from '../../../../../../OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { isEmpty } from 'lodash';
import { getJobConfig, postJobInfo, postJobInfoDraft } from '../../../../../../../redux/jobRecruiting/action';
import { showToast } from '../../../../../../../utils/common.util';
import { ToastMessages, stringSubstitute } from '../../../../../../OnBoarding/Organization/constants/onboarding.constants';
import { JobFormFieldNames, JobRequisition, JobStatusCode, JobStatusKey, NewJob } from '../../../../../Constant/constant';
import { OnboardingServices } from '../../../../../../../services/onboarding';
import { useNavigate } from 'react-router-dom';
import { JobServices } from '../../../../../../../services/jobRecuriting';
import { CandidateServices } from '../../../../../../../services/candidateServices';
import { useEffect } from 'react';
import { useCallback } from 'react';

const CreateNewJob = (props) => {
  const { setViewMode, jobConfig, isNewJob, idForJobDetails } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { jobFormData } = useSelector((state) => state.job);
  const { currentUserRoleId } = useSelector((state) => state.main);

  const [validationErrors, setValidationErrors] = useState({});
  const [jobFormInfo, setJobFormInfo] = useState(jobFormData);

  useEffect(() => {
    setJobFormInfo(jobFormData);
  }, [jobFormData]);

  const [hiringManagerDropDownOptions, setHiringManagerDropdownOptions] = useState([]);
  const [recruiterDropDownOptions, setRecruiterDropdownOptions] = useState([]);
  const [offerApproverDropDownOptions, setOfferApproverDropDownOptions] = useState([]);
  const [currencyDropDownOptions, setCurrencyDropDownOptions] = useState([]);
  const [jobTitleDropDownOptions, setJobTitleDropDownOptions] = useState([]);
  const [departmentDropDownOptions, setDepartmentDropDownOptions] = useState([]);
  const [countryDropDownOptions, setCountryDropDownOptions] = useState([]);
  const [locationDropDownOptions, setLocationDropDownOptions] = useState([]);
  const [newReplaceOptions, setNewReplaceOptions] = useState([]);
  const [skillSetDropdownOptions, setSkillSetDropdownOptions] = useState([]);
  const [higherEducationOptions, setHigherEducationOptions] = useState([]);
  const [employmentTypeOptions, setEmploymentTypeOptions] = useState([]);
  const [payFrequencyOptions, setPayFrequencyOptions] = useState([]);
  const [requisitionApproverOptions, setRequisitionApproverOptions] = useState([]);
  const [competencyOptions, setCompetencyOptions] = useState([]);
  const [jobLibraryOptions, setJobLibraryOptions] = useState([]);
  const [shiftSchedule, setShiftSchedule] = useState([]);

  const handleDropdownClick = (name) => {
    switch (name) {
      case JobFormFieldNames.Title: {
        return new OnboardingServices().getUserDesignation().then((res) => {
          setJobTitleDropDownOptions(res);
          return true;
        });
      }
      case JobFormFieldNames.Competency: {
        return new JobServices().getDropdownOptions(name).then((res) => {
          const Competency = res.map((item) => {
            return { ...item, name: item.competencyName };
          });
          setCompetencyOptions(Competency);
          return true;
        });
      }
      case JobFormFieldNames.Country: {
        return new OnboardingServices().getUserNationality().then((res) => {
          const countryNames = res.map((item) => {
            return { ...item, name: item.countryName };
          });
          setCountryDropDownOptions(countryNames);
          return true;
        });
      }
      case JobFormFieldNames.Currency: {
        return new JobServices().getDropdownOptions(name).then((res) => {
          const currency = res.map((item) => {
            return { ...item, name: item.currencyCode };
          });
          setCurrencyDropDownOptions(currency);
          return true;
        });
      }
      case JobFormFieldNames.Department: {
        return new JobServices().getDropdownOptions(name).then((res) => {
          setDepartmentDropDownOptions(res);
          return true;
        });
      }
      case JobFormFieldNames.EmployementType: {
        return new OnboardingServices().getUserEmploymentTypes().then((res) => {
          setEmploymentTypeOptions(res);
          return true;
        });
      }
      case JobFormFieldNames.HigherEducation: {
        return new JobServices().getDropdownOptions(name).then((res) => {
          setHigherEducationOptions(res);
          return true;
        });
      }
      case JobFormFieldNames.HiringManager: {
        return new JobServices().getDropdownOptions(name).then((res) => {
          setHiringManagerDropdownOptions(res);
          return true;
        });
      }
      case JobFormFieldNames.Location: {
        return new OnboardingServices().getLocationInfo().then((res) => {
          setLocationDropDownOptions(res);
          return true;
        });
      }
      case JobFormFieldNames.OfferApprover: {
        return new JobServices().getDropdownOptions(name).then((res) => {
          setOfferApproverDropDownOptions(res);
          return true;
        });
      }
      case JobFormFieldNames.PayFrequency: {
        return new JobServices().getDropdownOptions(name).then((res) => {
          setPayFrequencyOptions(res);
          return true;
        });
      }
      case JobFormFieldNames.Recruiter: {
        return new JobServices().getDropdownOptions(name).then((res) => {
          setRecruiterDropdownOptions(res);
          return true;
        });
      }
      case JobFormFieldNames.ReplacementOrNew: {
        return new JobServices().getDropdownOptions(name).then((res) => {
          setNewReplaceOptions(res);
          return true;
        });
      }
      case JobFormFieldNames.RequisitionApprover: {
        return new JobServices().getDropdownOptions(name).then((res) => {
          setRequisitionApproverOptions(res);
          return true;
        });
      }
      case JobFormFieldNames.SkillSet: {
        return new JobServices().getDropdownOptions(name).then((res) => {
          setSkillSetDropdownOptions(res);
          return true;
        });
      }
      case JobFormFieldNames.Library: {
        return new CandidateServices().getAllJobList().then((res) => {
          setJobLibraryOptions(res);
          return true;
        });
      }
      case JobFormFieldNames.ShiftSchedule: {
        return new JobServices().getDropdownOptions(name).then((res) => {
          setShiftSchedule(res);
          return true;
        });
      }
    }
  };

  const getDropdownOptions = (name) => {
    switch (name) {
      case JobFormFieldNames.Title: {
        return jobTitleDropDownOptions;
      }
      case JobFormFieldNames.Competency: {
        return competencyOptions;
      }
      case JobFormFieldNames.Country: {
        return countryDropDownOptions;
      }
      case JobFormFieldNames.Currency: {
        return currencyDropDownOptions;
      }
      case JobFormFieldNames.Department: {
        return departmentDropDownOptions;
      }
      case JobFormFieldNames.EmployementType: {
        return employmentTypeOptions;
      }
      case JobFormFieldNames.HigherEducation: {
        return higherEducationOptions;
      }
      case JobFormFieldNames.HiringManager: {
        return hiringManagerDropDownOptions;
      }
      case JobFormFieldNames.Location: {
        return locationDropDownOptions;
      }
      case JobFormFieldNames.OfferApprover: {
        return offerApproverDropDownOptions;
      }
      case JobFormFieldNames.PayFrequency: {
        return payFrequencyOptions;
      }
      case JobFormFieldNames.Recruiter: {
        return recruiterDropDownOptions;
      }
      case JobFormFieldNames.ReplacementOrNew: {
        return newReplaceOptions;
      }
      case JobFormFieldNames.RequisitionApprover: {
        return requisitionApproverOptions;
      }
      case JobFormFieldNames.SkillSet: {
        return skillSetDropdownOptions;
      }
      case JobFormFieldNames.Library: {
        return jobLibraryOptions;
      }
      case JobFormFieldNames.ShiftSchedule: {
        return shiftSchedule;
      }
    }
  };

  const handleChangeLibrary = (event) => {
    const value = event.target.value;
    const key = event.target.name;
    dispatch(getJobConfig(currentUserRoleId, value.id, true, isNewJob));
    setJobFormInfo((prev) => ({ ...prev, [key]: value }));
  };

  const handleChangeEmploymentType = useCallback((event) => {
    const key = event.target.name;
    const value = event.target.value;
    if (value.id !== EmpType.Contract) {
      setJobFormInfo((prev) => ({ ...prev, [JobFormFieldNames.ContractEndDate]: null }));
    }
    setValidationErrors((prev) => ({
      ...prev, [key]: '',
      [JobFormFieldNames.ContractEndDate]: '',
    }));
    setJobFormInfo((prev) => ({ ...prev, [key]: value }));
  }, [jobFormInfo[JobFormFieldNames.EmployementType]]);

  const formFields = useMemo(() => {
    return jobConfig.reduce((acc, item) => {
      const { fieldRefName } = item;
      let temp = { ...item };
      switch (fieldRefName) {
        case JobFormFieldNames.Library: {
          temp = { ...temp, onChange: handleChangeLibrary };
          break;
        }
        case JobFormFieldNames.ContractEndDate: {
          temp = {
            ...temp,
            isDisabled: jobFormInfo[JobFormFieldNames.EmployementType]?.id !== EmpType.Contract ?? true,
          };
          break;
        }
        case JobFormFieldNames.ContractStartDate: {
          if (jobFormInfo[JobFormFieldNames.EmployementType]?.id !== EmpType.Contract) {
            temp = {
              ...temp,
              fieldLabel: 'Planned Start Date'
            };
          }
          break;
        }
        case JobFormFieldNames.EmployementType: {
          temp = {
            ...temp,
            onChange: handleChangeEmploymentType
          };
          break;
        }
        default: {
          break;
        }
      }
      acc.push(temp);
      return acc;
    }, []);
  }, [jobConfig, jobFormInfo[JobFormFieldNames.EmployementType]]);

  const requiredFields = useMemo(() => {
    return getMandatoryFields(formFields);
  }, [formFields, jobFormInfo[JobFormFieldNames.EmployementType]]);

  const handleSave = () => {
    const errors = getValidationErrors(jobFormInfo, requiredFields);
    if (isEmpty(errors)) {
      dispatch(postJobInfo(jobFormInfo, currentUserRoleId)).then((res) => {
        showToast(ToastMessages.success.replace(stringSubstitute, toastMessage.success), ToastThemes.success);
        setViewMode(true);
        res.id && navigate(`/recruiting/job-requisition/${res.id}`, { replace: true });
        !res.id && dispatch(getJobConfig(currentUserRoleId, idForJobDetails));
      }).catch((err) => {
        showToast(err, ToastThemes.error);
      });
    } else {
      setValidationErrors(errors);
    }
  };

  const handleDraftClick = () => {
    dispatch(postJobInfoDraft(jobFormInfo, currentUserRoleId)).then((res) => {
      showToast(ToastMessages.success.replace(stringSubstitute, toastMessage.success), ToastThemes.success);
      res.id && navigate(`/recruiting/job-requisition/${res.id}`, { replace: true });
      setViewMode(true);
    }).catch((err) => {
      showToast(err, ToastThemes.error);
    });
  };

  const handleDiscard = () => {
    if (isNewJob) {
      navigate('../');
    } else {
      setViewMode(true);
    }
  };


  return (
    <Box>
      <Box mb={3} mx={-3} mt={-3}>
        <ActionHeader
          labelText={jobFormData?.[JobFormFieldNames.Title]?.name || NewJob}
          editButtonText='Draft'
          showDiscard
          showPublish
          showPublishModal={false}
          publishButtonText='Save'
          onPublishClick={handleSave}
          onEditClick={handleDraftClick}
          onClickDiscard={handleDiscard}
          labelCode={jobFormData?.[JobFormFieldNames.Code] ? jobFormData?.[JobFormFieldNames.Code] : ''}
          showSave={!jobFormData?.[JobFormFieldNames.StatusCode] || jobFormData?.[JobFormFieldNames.StatusCode]?.[JobStatusKey] === JobStatusCode.Draft}
        />
      </Box>
      <FormEdit
        list={formFields}
        formInfo={jobFormInfo}
        setFormData={setJobFormInfo}
        errors={validationErrors}
        setErrors={setValidationErrors}
        handleDropdownClick={handleDropdownClick}
        getDropdownOptions={getDropdownOptions}
        hideFields={false}
      />
    </Box>
  );
};

export default React.memo(CreateNewJob);
