import React, { useEffect, useState } from 'react';
import { CompBenServices } from '../../../../../../../services/compBen';
import { Box, Grid, IconButton } from '@mui/material';
import ActionHeader from '../../../../../../OnBoarding/Organization/ActionHeader/ActionHeader';
import AccordionTabs from '../../../../../../../atoms/Accordian/Accordian';
import BenefitExpression from '../benefit-expression/benefit-expression.component';
import { AddOutlined } from '@mui/icons-material';
import BenefitCommonExpressionEditor from '../benefit-expression/benefit-common-expression-editor';

const BlockPeriodConfig = (props) => {
    const { setShowConfig, object, setBenInfo, name } = props;

    const { conditionalExpression, defaultExpression } = object || {};
  
    const [formulaRules, setFormulaRules] = useState(
      conditionalExpression || [{}]
    );
  
    const [comp, setComp] = useState(defaultExpression || '');
    const [list, setList] = useState([]);
  
    useEffect(async () => {
      if (list.length === 0) {
        await new CompBenServices().getBenefitSystemField().then((res) => {
          setList(res);
        });
      }
    }, [list]);
  
    const handleSave = () => {
      let body = {};
      body = {
        conditionalExpression: formulaRules,
        defaultExpression: comp,
      };
  
      setBenInfo((prev) => ({
        ...prev,
        blockPeriodMonthsConfig: body,
      }));
      setShowConfig('');
    };
  
    return (
      <Box>
        <Box mb={3} mx={-3} mt={-3}>
          <ActionHeader
            labelText={`${name} -- Block Period Config`}
            showPublish
            showBackButton
            showPublishModal={false}
            publishButtonText="Save"
            onBackClick={() => setShowConfig('')}
            onPublishClick={handleSave}
            showSave={false}
          />
        </Box>
        <Box>
          <Grid container rowSpacing={3} columnSpacing={8} mt={2}>
            <Grid item xs={12} md={12}>
              {formulaRules.map((item, index) => {
                return (
                  <Box key={index}>
                    <AccordionTabs
                      title={`Rule ${index + 1}`}
                      bgColorSummary="rgba(167, 236, 226, 0.3)"
                      tabDetails={
                        <Box>
                          <BenefitExpression
                            setFormulaRules={setFormulaRules}
                            index={index}
                            data={item}
                            noOfRules={formulaRules.length}
                            fieldList={list}
                            configType="blockPeriod"
                          />
                        </Box>
                      }
                    />
                  </Box>
                );
              })}
              <IconButton
                onClick={() =>
                  setFormulaRules((prev) => {
                    return [...prev, {}];
                  })
                }
                sx={{
                  width: 'max-content',
                }}
              >
                <AddOutlined />
              </IconButton>
              <Box>
                <BenefitCommonExpressionEditor
                  setFormInfo={setComp}
                  computeExpression={comp}
                  fieldList={list}
                  configType="blockPeriod"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

export default BlockPeriodConfig;