import API from './api';

export class CompBenServices extends API {
  static baseURL = API.env.REACT_APP_BASE_API_LOCAL + '/comp-benefits/' || '';
  constructor() {
    super(CompBenServices.baseURL);
  }

  getDropDownOption = async (id) =>
    this.responsify(await this.instance.get(`drop-down/${id}`));

  getBenefitSystemField = async () =>
    this.responsify(await this.instance.get('/list-fields'));

  getAllBenefitsList = async (roleId) =>
    this.responsify(await this.instance.get('/list', { params: { roleId } }));

  getBenefitsDetails = async (id, roleId) =>
    this.responsify(
      await this.instance.get(`/benefit-details/${id}`, { params: { roleId } })
    );

  postBenefits = async (body) =>
    this.responsify(await this.instance.post('/create', body));

  assignBenefits = async (body) =>
    this.responsify(await this.instance.post('/assign-benefit', body));

  upsertBenefits = async (body) =>
    this.responsify(await this.instance.post('/upsert-benefit', body));

  getAllUserBenefitsList = async (roleId, benefitId, startDate, endDate, search) =>
    this.responsify(await this.instance.get(`/get-users/${benefitId}`, { params: { roleId, startDate, endDate, search } }));

  postRemainingBalance = async (body, roleId) =>
    this.responsify(await this.instance.put('/return-remaining-balance', body, {
      params: {
        roleId
      }
    }));
}
