import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import AddIcon from '@mui/icons-material/Add';
import TagIcon from '@mui/icons-material/Tag';
import { FilterAltOffOutlined, PlaylistRemove } from '@mui/icons-material';

const customScrollbarStyle = {
  '&::-webkit-scrollbar': {
    width: '.3em',
    height: '.3em',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#A4A4A4',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator, callback) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  callback(stabilizedThis?.map((el) => el[0]));
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    selectAllChecked = false,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    onClickAddRow,
    showCheckbox,
    showSerialNo,
    setTableRef = () => { },
    showAddIcon,
    addDisabled,
    allowSelectAll = false,

  } = props;
  const [checked, setChecked] = useState(selectAllChecked);
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const handleAddClick = () => {
    onClickAddRow();
    setTableRef();
  };
  const onChange = (event) => {
    setChecked((val) => !val);
    onSelectAllClick(event);
  };

  useEffect(() => {
    if (numSelected === 0) {
      setChecked(false);
    }
  }, [numSelected]);
  
  return (
    <TableHead>
      <TableRow>
        {showCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={checked}
              onChange={onChange}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
              sx={allowSelectAll ? {} : { display: 'none' }}
            />
          </TableCell>
        )}
        {showSerialNo && (
          <TableCell key="id">
            <TagIcon sx={{ color: 'neutral.dark80' }} />
          </TableCell>
        )}
        {headCells.filter((item) => !item.hide).map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Box display='flex' alignItems='center'>
                {headCell.showFilterIcon && <IconButton onClick={headCell.clear}><FilterAltOffOutlined sx={{ color: 'primary.main', cursor: 'pointer', fontSize: '18px' }} /></IconButton>}
                <Typography variant="subtitle2" color={'neutral.dark80'}>
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  ) : null}
                </Typography>
              </Box>
            </TableSortLabel>
          </TableCell>
        ))}
        {showAddIcon &&
          <TableCell key='action' >
            <IconButton onClick={() => handleAddClick()} disabled={addDisabled}>
              <AddIcon sx={{ color: addDisabled ? 'neutral.light40' : 'neutral.dark80' }} />
            </IconButton>
          </TableCell>}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        ></Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable(props) {
  const {
    rowsData,
    headCells,
    onAddClick,
    setTableRef,
    showCheckbox,
    showSerialNo,
    showAddIcon,
    checkedItems,
    setCheckedItems,
    callback = () => { },
    isDisabledCheckbox = () => { },
    addDisabled,
    onRowClick = () => { },
    allowSelectAll,
    onSelectAllClick = (event) => {
      let tempIds = [];
      if (event.target.checked) {
        tempIds = rowsData.map((item) => item.id.value);
      }
      setCheckedItems(tempIds);
    },
    emptyDashboardMsg
  } = props;

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, index) => {
    const selectedIndex = selected.indexOf(index);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, index);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    onRowClick(index);
  };

  const isSelected = (id) => checkedItems?.find((value) => value === id || value.id && value.id === id.id);
  const tableRowRef = useRef();

  const handleCheckboxChange = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      setCheckedItems((prev) => [...prev, value]);
    } else {
      if (value.id) {
        setCheckedItems((prev) => prev.filter((item) => item.id !== value.id));
      } else {
        setCheckedItems((prev) => prev.filter((item) => item !== value));
      }
    }
  };

  const checkDisabledCheckbox = (row) => {
    return isDisabledCheckbox(row);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer sx={{ ...customScrollbarStyle, maxHeight: 'calc(100vh - 360px)' }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size='small'
            stickyHeader aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={checkedItems?.length || selected?.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rowsData?.length}
              headCells={headCells}
              onClickAddRow={() => onAddClick()}
              showCheckbox={showCheckbox}
              showSerialNo={showSerialNo}
              setTableRef={() => setTableRef(tableRowRef)}
              showAddIcon={showAddIcon}
              addDisabled={addDisabled}
              allowSelectAll={allowSelectAll}
              onSelectAllClick={onSelectAllClick}
            />
            <TableBody>
              {stableSort(rowsData, getComparator(order, orderBy), callback)?.map(
                (row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, index)}
                      tabIndex={-1}
                      key={index}
                      sx={{ cursor: 'pointer' }}
                      ref={tableRowRef}
                    >
                      {showCheckbox && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isSelected(row?.id?.value) || false}
                            disabled={checkDisabledCheckbox(row)}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                            onChange={(e) => handleCheckboxChange({ target: { ...e.target, value: row.id.value } })}
                          />
                        </TableCell>
                      )}
                      {showSerialNo && (
                        <TableCell key={indexedDB} align="justify">
                          {index + 1}
                        </TableCell>
                      )}
                      {Object.keys(row).filter((item) => !row[item]?.hide).map((key) => (
                        <TableCell onClick={(event) => handleClick(event, index)} key={key}
                          align={headCells.find(cell => cell.id === key)?.numeric ? 'right' : 'left'}  sx={{ whiteSpace: 'pre-wrap' }}>
                          {row[key]}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {rowsData?.length === 0 && (
          <Box
            display="flex"
            justifyContent="center"
            minHeight={300}
            alignItems="center"
            flexDirection="column"
          >
            <PlaylistRemove
              sx={{ height: 100, width: 100, mb: 2, color: 'neutral.dark60' }}
            />
            <Typography variant="h2" color="neutral.dark60">
              {emptyDashboardMsg || 'No records to list'}
            </Typography>
          </Box>
        )}
      </Paper>
    </Box>
  );
}
