import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getFormattedValues } from '../../../../utils/formatter';
import FieldView from '../../../molecules/FieldView/FieldView';
import { entitlementsConfigs } from '../../../form-builder/utils/entitlement.util';

const EntitlementsView = (props) => {
  const { entitlements = {} } = props;
  const {
    startDate = '',
    endDate = '',
    entitlementAmt = 0,
    entitlementBalanceAmt = 0,
  } = entitlements;
  const currencySymbol = JSON.parse(
    localStorage.getItem('orgInfo')
  )?.currencySymbol;
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ flexGrow: 1, p: 4, my: 1 }}>
        <Grid container rowSpacing={3} columnSpacing={12} pr={8}>
          {entitlementsConfigs.map(function (data) {
            return (
              <>
                <Grid item md={3}>
                  <FieldView
                    labelText={data.label}
                    labelValue={data.getter(
                      {
                        startDate,
                        endDate,
                        entitlementAmt,
                        entitlementBalanceAmt,
                      },
                      currencySymbol
                    )}
                    required={data.required}
                  />
                </Grid>
              </>
            );
          })}
        </Grid>
      </Paper>
    </Box>
  );
};

export default EntitlementsView;
