import React from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import PendingIcon from '@mui/icons-material/Pending';
export const STATUS_ICON = {
    PENDING: <EditOutlinedIcon
        sx={{
            background: '#FFF',
            color: 'neutral.dark80',
        }}
    />,
    PAYMENT_PROCESSED: <DoneOutlinedIcon
        sx={{
            background: '#FFF',
            color: 'primary.main',
        }}
    />,
    APPROVED: <DoneOutlinedIcon
        sx={{
            background: '#FFF',
            color: 'primary.main',
        }}
    />,
    DRAFT: 'DRAFT',
    RE_SUBMITTED: 'RE-SUBMITTED',
    RE_SUBMIT: 'RE-SUBMIT',
    REJECTED: <ClearOutlinedIcon
        sx={{
            background: '#FFF',
            color: 'accentSecondary.main',
        }}
    />,
    STATUS: <PendingIcon
        sx={{
            background: '#FFF',
            color: 'neutral.dark80',
        }}
    />,
    DELETED: <ClearOutlinedIcon
        sx={{
            background: '#FFF',
            color: 'accentSecondary.main',
        }}
    />,
};