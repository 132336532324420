import React, { useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { approvalWorkflowServices } from '../../../../../services/approvalWokflowService';
import { showToast } from '../../../../../utils/common.util';
import { ToastThemes } from '../../../../../constants/common';
import { isNotEmptyString } from '../../utils/onboarding.util';
import ApprovalWorkflowRejectModal from '../Modals/RejectModal';
import ApprovalWorkflowApproveModal from '../Modals/ApproveModal';
import { PayrollStatus } from '../../../../Payroll/Constant/Constant';
import FormEdit from '../../../common/Form/FormEdit';
import CircularLoader from '../../../../../atoms/CircularLoader/circular-loader';

export default function ApprovalWorkflowFooterCommon(props) {
  const {
    recordId,
    moduleId,
    reasons = [],
    handleStatusUpdate = () => {},
    auditLogs = [],
    getDetails = () => {},
    refId,
    setIsEditable,
    list,
    isAdditionalInfo,
    saveDetails = () => {},
    moduleName,
    handleApprove = () => {},
    beforeApprovalValidations = () => {
      return true;
    },
    dataForApproval,
    claimRaisingFor,
    isAuthorized,
    setisAuthorized,
    approvalRemarksPermission={}
  } = props;
  
  const navigate = useNavigate();
  const {
    currentUserRoleId,
    userInfo: { id: userId },
  } = useSelector((state) => state.main);

  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [modelTitle, setModelTitle] = useState('');
  const [approvalRemarks, setApprovalRemarks] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState({});
  const [modelDescription, setModelDescription] = useState('');
  const [loading, setLoading] = useState(false);


  const getApprovalWorkflowPermission = async () => {
    await new approvalWorkflowServices()
      .getApprovalPermissions(
        moduleId,
        recordId,
        currentUserRoleId,
        userId,
        'others',
        moduleName
      )
      .then((res) => {
        setisAuthorized(res.authorised);
        setIsEditable(res.edit);
      })
      .catch((err) => {
        showToast(err, ToastThemes.error);
      });
  };

  const handleApproveRequest = () => {
    setModelTitle('Approve');
    setOpenModel(true);
  };

  const handleCloseModel = () => {
    setOpenModel(false);
    setModelTitle('');
    setModelDescription('');
  };

  const handleSubmit = async (body) => {
    try {
      return await submitApprovalRejection(body);
    } catch (err) {
      console.error(err);
    }
  };

  const handleApproveClick = () => {
    if (beforeApprovalValidations()) {
      if (!approvalRemarksPermission?.isApprovalRemarksRequired){
        handleSubmit({
          recordId,
          moduleId,
          action: 'APPROVED',
          reasons: [],
        });
        return;
      }
      handleApproveRequest();
    }
  };

  const afterApproveReject = (action) => {
    switch (action) {
      case 'RE-SUBMIT': {
        handleStatusUpdate(PayrollStatus.SendBack);
        saveDetails(PayrollStatus.SendBack);
        break;
      }
      case 'APPROVED': {
        handleStatusUpdate(PayrollStatus.Approved);
        saveDetails(PayrollStatus.Approved,additionalInfo,true);
        break;
      }
      case 'REJECTED': {
        handleStatusUpdate(PayrollStatus.Rejected);
        saveDetails(PayrollStatus.Rejected);
        break;
      }
      case 'PENDING': {
        saveDetails(PayrollStatus.Pending,additionalInfo);
        break;
      }
      default: {
        break;
      }
    }
  };
  const submitApprovalRejection = async (body) => {
    const { remark, reasons, action } = body;
    try {
      setLoading(true);
      const approvalService = new approvalWorkflowServices();
      const response = await approvalService
        .approveOrRejectApprovalWorflow(
          recordId,
          moduleId,
          action,
          remark,
          reasons,
          refId,
          currentUserRoleId,
          dataForApproval,
          claimRaisingFor
        )
        .then(async (res) => {
          afterApproveReject(res.status);
          getDetails();
          handleApprove();
          await getApprovalWorkflowPermission();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
      setModelTitle('Success');
      setModelDescription('Reject or Send Back Successfully');
      return { success: response, error: null };
    } catch (error) {
      setModelTitle('Error');
      setModelDescription(error?.response?.data?.message || error.message);
      return {
        success: false,
        error: error?.response?.data?.message || error.message,
      };
    }
  };

  useEffect(() => {
    if ((moduleId || moduleName) && recordId && currentUserRoleId && userId)
      getApprovalWorkflowPermission();
  }, [recordId, currentUserRoleId, userId, moduleId, moduleName]);

  return (
    <>
      <CircularLoader show={loading} />
      {isAuthorized && (
        <div className="footer-cn">
          <Grid item md={12}>
            <Box sx={{ p: 2, mt: 2 }}>
              <Box display="flex" gap="10px" justifyContent="flex-end">
                {
                  <>
                    <Button
                      size="medium"
                      sx={{
                        bgcolor: 'neutral.light80',
                        color: 'neutral.dark80',
                        px: 2,
                        textTransform: 'capitalize',
                      }}
                      onClick={() => navigate(-1)}
                    >
                      Review Later
                    </Button>
                    <Button
                      variant="outlined"
                      size="medium"
                      onClick={() => {
                        setRejectModalOpen(true);
                      }}
                    >
                      Send Back
                    </Button>
                    <Button
                      variant="contained"
                      size="medium"
                      onClick={handleApproveClick}
                    >
                      Approve
                    </Button>
                  </>
                }
              </Box>
            </Box>
          </Grid>
          <ApprovalWorkflowRejectModal
            handleSubmit={handleSubmit}
            open={rejectModalOpen}
            setOpen={setRejectModalOpen}
            reasonsList={reasons}
            recordId={recordId}
            moduleId={moduleId}
            approvalRemarksPermission={approvalRemarksPermission}
          />
          <Grid item md={12}>
            <ApprovalWorkflowApproveModal
              showModal={openModel}
              component
              componentProps={{
                onInputChange: (event) =>
                  setApprovalRemarks(event.target.value),
                inputValue: approvalRemarks,
                inputPlaceHolder: 'Add Remarks',
              }}
              severity={'success'}
              onClickClose={handleCloseModel}
              primaryAction={'Approve'}
              primaryButtonDisabled={!isNotEmptyString(approvalRemarks)}
              onClickConfirm={() =>
                handleSubmit({
                  recordId,
                  moduleId,
                  action: 'APPROVED',
                  remark: approvalRemarks,
                  reasons: [],
                })
              }
              title={modelTitle}
              description={
                <>
                  {' '}
                  {modelDescription && (
                    <>
                      {modelDescription}
                      <br />
                    </>
                  )}
                  {isAdditionalInfo && (
                    <Box marginLeft={4} marginRight={4}>
                      <FormEdit
                        list={list}
                        padding={0}
                        setFormData={setAdditionalInfo}
                        formInfo={additionalInfo}
                      />
                    </Box>
                  )}{' '}
                </>
              }
            />
          </Grid>
        </div>
      )}
    </>
  );
}
