import { FormFieldTypes } from '../../constants/common';
import { BuilderAddFieldNames } from './form-builder.constants';

export const AdditionFields = [
    {
        id: 1,
        fieldLabel: 'Text',
        placeholderText: '',
        fieldType: FormFieldTypes.Text,
        isRequired: true,
        fieldRefName: BuilderAddFieldNames.Text,
      },
      {
        id: 1,
        fieldLabel: 'Number',
        placeholderText: '',
        fieldType: FormFieldTypes.Number,
        isRequired: true,
        fieldRefName: BuilderAddFieldNames.Number,
      },
      {
        id: 1,
        fieldLabel: 'Single Select',
        placeholderText: '',
        fieldType: FormFieldTypes.SingleSelect,
        isRequired: true,
        fieldRefName: BuilderAddFieldNames.SingleSelect,
      },
      {
        id: 1,
        fieldLabel: 'Multi Select',
        placeholderText: '',
        fieldType: FormFieldTypes.MutliSelect,
        isRequired: true,
        fieldRefName: BuilderAddFieldNames.MultiSelect,
      },
      {
        id: 1,
        fieldLabel: 'Date',
        placeholderText: '',
        fieldType: FormFieldTypes.Date,
        isRequired: true,
        fieldRefName: BuilderAddFieldNames.Date,
      },
      {
        id: 1,
        fieldLabel: 'Date Time',
        placeholderText: '',
        fieldType: FormFieldTypes.DateTime,
        isRequired: true,
        fieldRefName: BuilderAddFieldNames.DateAndTime,
      },
      {
        id: 1,
        fieldLabel: 'Time',
        placeholderText: '',
        fieldType: FormFieldTypes.Time,
        isRequired: true,
        fieldRefName: BuilderAddFieldNames.Time,
      },
      {
        id: 1,
        fieldLabel: 'File Upload',
        placeholderText: '',
        fieldType: FormFieldTypes.File,
        isRequired: true,
        fieldRefName: BuilderAddFieldNames.FileUpload,
      },
      {
        id: 1,
        fieldLabel: 'Multi File Upload',
        placeholderText: '',
        fieldType: FormFieldTypes.MultiFileUpload,
        isRequired: true,
        fieldRefName: BuilderAddFieldNames.MultiFileUpload,
      },
      {
        id: 1,
        fieldLabel: 'Checkbox',
        placeholderText: '',
        fieldType: FormFieldTypes.CheckBox,
        isRequired: true,
        fieldRefName: BuilderAddFieldNames.Checkbox,
      },
      {
        id: 1,
        fieldLabel: 'MultiLine Text',
        placeholderText: '',
        fieldType: FormFieldTypes.MultiLineText,
        isRequired: true,
        fieldRefName: BuilderAddFieldNames.MultiLineText,
      },
      {
        id: 1,
        fieldLabel: 'Formula',
        placeholderText: '',
        fieldType: FormFieldTypes.Formula,
        isRequired: true,
        fieldRefName: BuilderAddFieldNames.Formula,
      },
      {
        id: 1,
        fieldLabel: 'Date Range',
        placeholderText: '',
        fieldType: FormFieldTypes.DateRange,
        isRequired: true,
        fieldRefName: BuilderAddFieldNames.Formula,
      },
      {
        id: 1,
        fieldLabel: 'Month',
        placeholderText: '',
        fieldType: FormFieldTypes.Month,
        isRequired: true,
        fieldRefName: BuilderAddFieldNames.Month,
      },
      {
        id: 1,
        fieldLabel: 'Year',
        placeholderText: '',
        fieldType: FormFieldTypes.Year,
        isRequired: true,
        fieldRefName: BuilderAddFieldNames.Year,
      },
      {
        id: 1,
        fieldLabel: 'Auto Populate',
        placeholderText: '',
        fieldType: FormFieldTypes.AutoPopulate,
        isRequired: true,
        fieldRefName: BuilderAddFieldNames.AutoPopulate,
      },
      {
        id: 1,
        fieldLabel: 'Pro Rate Field',
        placeholderText: '',
        fieldType: FormFieldTypes.ProRateField,
        isRequired: true,
        fieldRefName: BuilderAddFieldNames.ProRateField,
      },
      {
        id: 1,
        fieldLabel: 'Ent Auto Populate',
        placeholderText: '',
        fieldType: FormFieldTypes.EntitlementAutoPopulate,
        isRequired: true,
        fieldRefName: BuilderAddFieldNames.EntitlementAutoPopulate,
      },
      {
        id: 1,
        fieldLabel: 'User Based SSL',
        placeholderText: '',
        fieldType: FormFieldTypes.UserBasedSSL,
        isRequired: true,
        fieldRefName: BuilderAddFieldNames.UserBasedSSL,
      },
      {
        id: 1,
        fieldLabel: 'User Based Field',
        placeholderText: '',
        fieldType: FormFieldTypes.UserBasedField,
        isRequired: true,
        fieldRefName: BuilderAddFieldNames.UserBasedField,
      },
      {
        id: 1,
        fieldLabel: 'Currency Ex Field',
        placeholderText: '',
        fieldType: FormFieldTypes.CurrencyExchangeField,
        isRequired: true,
        fieldRefName: BuilderAddFieldNames.UserBasedField,
      },
];