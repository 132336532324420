import React, { useEffect, useState } from 'react';
import { conditionInitialVal } from '../../../../../comp-ben-utlis/comp-ben-utils';
import { getFormBody } from '../../../../../../OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import SelectTag from '../../../../../../../atoms/Select/SelectTag';
import { ruleConjunctionOptions } from '../../../../../../form-builder/utils/conditional.util';
import { DeleteOutline } from '@mui/icons-material';
import BenefitAddConditional from './benefit-add-conditional.component';
import BenefitCalculationExpression from './benefit-calculation-expression.component';
import ApiSelectTag from '../../../../../../../atoms/Select/ApiSelectTag';
import { FormFieldTypes } from '../../../../../../../constants/common';

const BenefitExpression = (props) => {
  const { setFormulaRules, data, index, noOfRules, fieldList, configType } =
    props;
  const { conditions: fromInfo, ...rest } = data;

  const [conditions, setConditions] = useState(
    fromInfo || [conditionInitialVal]
  );
  const [ruleFormState, setRuleFormState] = useState(rest || {});

  useEffect(() => {
    setFormulaRules((prev) => {
      const newData = [...prev];
      newData[index] = { ...ruleFormState };
      return newData;
    });
  }, [ruleFormState]);

  useEffect(() => {
    setRuleFormState((prev) => ({
      ...prev,
      conditions: conditions.map((item) => getFormBody(item)),
    }));
  }, [conditions]);

  const handleDropDown = (event) => {
    const { value, name } = event.target;
    setRuleFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDropDownForExpression = (event) => {
    const { value, name } = event.target;
    setRuleFormState((prev) => ({
      ...prev,
      [name]: value?.fieldColumnName,
    }));
  };

  const handleFieldClick = (field) => {
    const { fieldColumnName } = field;
    setRuleFormState((prev) => {
      return {
        ...prev,
        expression: `${prev.expression || ''} info.${fieldColumnName}`,
      };
    });
  };

  const handleFunctionClick = (field) => {
    const { refName } = field;
    setRuleFormState((prev) => {
      return {
        ...prev,
        expression: `${prev.expression || ''} ${refName}`,
      };
    });
  };

  const handleCalculatorClick = (digit) => {
    setRuleFormState((prev) => {
      const { expression = '' } = prev;
      const lastChar = expression.charAt(expression.length - 1);
      const allowSpace =
        (isNaN(lastChar) && lastChar !== '.') ||
        (isNaN(digit) && digit !== '.');
      const newExpr = `${prev.expression || ''}${
        allowSpace ? ` ${digit}` : `${digit}`
      }`;
      return {
        ...prev,
        expression: newExpr,
      };
    });
  };

  const handleBackClick = () => {
    setRuleFormState((prev) => {
      return { ...prev, expression: '' };
    });
  };

  const handleDelete = () => {
    if (noOfRules === 1) {
      setRuleFormState({});
    } else {
      setFormulaRules((prev) => {
        const newConditions = [...prev];
        newConditions.splice(index, 1);
        setRuleFormState(newConditions[index]);
        return newConditions;
      });
    }
  };

  const getExpression = () => {
    switch (configType) {
      case 'startDate': {
        const newList = [
          {
            id: '01',
            fieldDisplayName: 'Calender Year',
            name: 'startOfYear',
            fieldType: 'DT',
            fieldDatatypeCode: 'DT',
            fieldColumnName: 'START_OF_YEAR',
          },
          ...fieldList.filter((item) => item.fieldType === FormFieldTypes.Date),
        ];
        return (
          <ApiSelectTag
            defaultValue={'Select'}
            fullWidth
            size="small"
            dropDownList={newList.map((item) => {
                return { ...item, name: item.fieldDisplayName };
              })}
            name="expression"
            value={
              newList.find(
                (item) => item.fieldColumnName === ruleFormState.expression
              )?.fieldDisplayName
            }
            onchange={handleDropDownForExpression}
          />
        );
      }
      case 'blockPeriod': {
        const newList = [
            {
              id: '1',
              fieldDisplayName: '12',
              name: '12',
              fieldType: 'TXT',
              fieldDatatypeCode: 'NUM',
              fieldColumnName: '12',
            },
            {
                id: '2',
                fieldDisplayName: '24',
                name: '24',
                fieldType: 'TXT',
                fieldDatatypeCode: 'NUM',
                fieldColumnName: '24',
              },
          ];
          return (
            <ApiSelectTag
              defaultValue={'Select'}
              fullWidth
              size="small"
              dropDownList={newList}
              name="expression"
              value={
                newList.find(
                  (item) => item.fieldColumnName === ruleFormState.expression
                )?.fieldDisplayName
              }
              onchange={handleDropDownForExpression}
            />
          );
      }
      default: {
        return (
          <>
            <BenefitCalculationExpression
              handleFieldClick={handleFieldClick}
              handleCalculatorClick={handleCalculatorClick}
              expressionVal={ruleFormState.expression}
              handleBackClick={handleBackClick}
              handleFunctionClick={handleFunctionClick}
              fieldList={fieldList}
            />
          </>
        );
      }
    }
  };

  return (
    <>
      <Grid container rowSpacing={3} columnSpacing={8}>
        <Grid item xs={12} md={12} display="flex">
          <SelectTag
            name="conjunction"
            dropDownList={ruleConjunctionOptions}
            size="small"
            fullWidth
            value={ruleFormState?.conjunction}
            onchange={handleDropDown}
          />
          <Typography display="flex" alignItems="center">
            {' '}
            &nbsp; of the conditions are met
          </Typography>
          <Box display="flex" alignItems="center"></Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper sx={{ p: 2 }}>
            {conditions.map((item, index) => {
              return (
                <BenefitAddConditional
                  key={index}
                  index={index}
                  setConditions={setConditions}
                  condition={item}
                  noOfConditions={conditions.length}
                  setFormState={setRuleFormState}
                  fieldList={fieldList}
                />
              );
            })}
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          {getExpression()}
        </Grid>
        <Grid item xs={12} md={12} display="flex" justifyContent="flex-end">
          <Button
            color="error"
            variant="outlined"
            sx={{
              borderColor: 'white',
              borderRadius: '20px',
              textTransform: 'capitalize',
            }}
            onClick={handleDelete}
          >
            <DeleteOutline sx={{ width: '24px', height: '24px', mr: '5px' }} />
            Delete
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default BenefitExpression;
