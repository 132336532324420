import React, { useCallback, useState } from 'react';
import ApiSelectTag from '../../../../atoms/Select/ApiSelectTag';
import {
  userEntAddAndSubField,
  userEntitlementsFields,
} from './user-entitlement.utils';
import { BuilderServices } from '../../../../services/builder';
import { EntitlementsService } from '../../../../services/entitlementsService';
import { showToast } from '../../../../utils/common.util';
import { ToastThemes } from '../../../../constants/common';
import { Box, Grid } from '@mui/material';
import { entitlementsConfigs } from '../../../form-builder/utils/entitlement.util';
import FieldView from '../../../molecules/FieldView/FieldView';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';
import FormEdit from '../../common/Form/FormEdit';
import ActionHeader from '../../Organization/ActionHeader/ActionHeader';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

const UserEntitlement = (props) => {
  const { empId, userName } = props;
  const [benefitTypes, setBenefitTypes] = useState([]);
  const [formInfo, setFormInfo] = useState({});
  const [entitlementView, setEntitlementView] = useState({});
  const [loading, setLoading] = useState(false);
  const { currentUserRoleId } = useSelector((state) => state.main);

  const currencySymbol = JSON.parse(
    localStorage.getItem('orgInfo')
  )?.currencySymbol;
  
  const handleDropdownClick = () => {
    return new EntitlementsService()
      .getBenfitsRole(currentUserRoleId)
      .then((res) => {
        setBenefitTypes(res);
        return true;
      });
  };

  const onChangeBenefit = (event) => {
    const { value } = event.target;
    new EntitlementsService()
      .getUserEntitlement(value.id, empId)
      .then((res) => {
        setEntitlementView(res);
      })
      .catch((err) => {
        setEntitlementView({});
        showToast(err?.response?.data?.message, ToastThemes.error);
      });
    setFormInfo((prev) => {
      return {
        ...prev,
        [userEntitlementsFields.benefitTypes]: value?.id,
        [userEntitlementsFields.addAmount]: 0,
        [userEntitlementsFields.deductionAmount]: 0,
      };
    });
  };

  const isDisabledSave = () => {
    if (
      (formInfo?.[userEntitlementsFields.addAmount] || 0) -
        (formInfo?.[userEntitlementsFields.deductionAmount] || 0) ===
        0 ||
      isEmpty(entitlementView)
    ) {
      return true;
    }
    return false;
  };

  const updateUserEntitlement = () => {
    if (!formInfo?.[userEntitlementsFields.benefitTypes]) {
      showToast('Select Benefit Type', ToastThemes.error);
      return;
    }
    setLoading(true);
    new EntitlementsService()
      .updateUserEntitlement(formInfo, empId)
      .then((res) => {
        setEntitlementView((prev) => {
          return { ...prev, ...res };
        });
      })
      .catch((err) => {
        showToast(err?.response?.data?.message, ToastThemes.error);
      })
      .finally(() => {
        setLoading(false);
        setFormInfo((prev) => {
          return {
            ...prev,
            [userEntitlementsFields.addAmount]: 0,
            [userEntitlementsFields.deductionAmount]: 0,
          };
        });
      });
  };

  return (
    <>
      <CircularLoader show={loading} />
      <Box mb={3} mx={-3} mt={-3}>
        <ActionHeader
          labelText={userName || 'New User'}
          showPublish
          showPublishModal={false}
          publishButtonText="Save"
          onPublishClick={updateUserEntitlement}
          showSave={false}
          disabledPublish={isDisabledSave()}
        />
      </Box>
      <Grid container rowSpacing={3} columnSpacing={12} pr={8}>
        <Grid item md={6}>
          <ApiSelectTag
            labelText={'Select Benefit Type'}
            onOpen={handleDropdownClick}
            value={formInfo?.[userEntitlementsFields.benefitTypes]}
            dropDownList={benefitTypes}
            onchange={onChangeBenefit}
            name={userEntitlementsFields.benefitTypes}
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
      <Box sx={{ width: '100%' }} mt={2} mb={2}>
        <Grid container rowSpacing={3} columnSpacing={12} pr={8}>
          {entitlementsConfigs.map(function (data) {
            const {
              startDate,
              endDate,
              entitlementAmt,
              entitlementBalanceAmt,
            } = entitlementView;
            return (
              <>
                <Grid item md={3}>
                  <FieldView
                    labelText={data.label}
                    labelValue={data.getter(
                      {
                        startDate,
                        endDate,
                        entitlementAmt,
                        entitlementBalanceAmt,
                      },
                      currencySymbol
                    )}
                    required={data.required}
                  />
                </Grid>
              </>
            );
          })}
        </Grid>
      </Box>
      {!isEmpty(entitlementView) && (
        <FormEdit
          list={userEntAddAndSubField}
          formInfo={formInfo}
          setFormData={setFormInfo}
          modal={false}
          padding={0}
          hideFields={false}
        />
      )}
    </>
  );
};

export default UserEntitlement;
