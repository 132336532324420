import React from 'react';
import moment from 'moment';

const AuditStepper = ({auditTrailData}) => {




  return (
    <React.Fragment>

    {auditTrailData && auditTrailData.map((step,i) => {
        return (
            <div key={step.name} className={`step ${step?.status ? 'completed' : 'not-completed'}`}>
            <div className='v-stepper'>
              <div className={`circle ${step?.isCompleted ? 'not-active' : 'completed'}`}><span>{i + 1}</span></div>
              <div className='line'></div>
            </div>
    
            <div className='content'>
               <div className="step-status">
                <div className="status">
                   <span className={`${(step.status == 'REJECTED' || step.status == 'RE-SUBMIT') ? 'danger' : 'success'}`}>{step?.status || 'Status'}</span> 
                </div>
                </div> 
              <div className="step-name">
                <h5>{step?.designation || step?.role}</h5>
                {step?.firstname && (
                  <React.Fragment>
                <span>Name : {`${step?.firstname} ${step?.lastname}`}</span>
    
                <br/>
                <span>{step?.message}</span>
                <br/>
                <span>Date : {moment(step?.createdat).format('DD-MM-YYYY')}</span>
                </React.Fragment>
    )}
                </div>

            {step?.reasons?.length > 0 &&
            <React.Fragment>
                <div className="step-send-back-response">
                <h5>Send Back Response</h5>
                <ul>
                    {step.reasons.map((reason) => {
                        return <li key={reason}>{reason}</li>;
                    })}
                </ul>
                </div>
                </React.Fragment>
            }
            </div>
          </div>
        );
    })}
    </React.Fragment>
  );
};

export default AuditStepper;
