import { FormFieldTypes } from '../../../../constants/common';
import { preventKeyPress } from '../../../../utils/common.util';

export const userEntitlementsFields = {
    benefitTypes:'benefitId',
    addAmount:'addAmount',
    deductionAmount:'deductionAmount'
};

export const userEntAddAndSubField =[
    {
        id: 1,
        fieldLabel: 'Addition',
        placeholderText: 'Addition',
        fieldType: FormFieldTypes.Text,
        dataType:FormFieldTypes.Number,
        isRequired: false,
        isHidden:false,
        isReadOnly:false,
        fieldRefName: 'addAmount',
        size: 'small',
        fullWidth: true,
        fieldSize:3,
        onKeyDown: preventKeyPress
      },
      {
        id: 2,
        fieldLabel: 'Subtraction',
        placeholderText: 'Subtraction',
        fieldType: FormFieldTypes.Text,
        dataType:FormFieldTypes.Number,
        isRequired: false,
        isHidden:false,
        isReadOnly:false,
        fieldRefName: 'deductionAmount',
        size: 'small',
        fullWidth: true,
        fieldSize:3,
        onKeyDown: preventKeyPress
      },
    ];