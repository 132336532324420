export const JobFormFieldNames = {
    Title: 'fkJobTitle',
    Competency : 'fkCompetency',
    Country: 'fkCountry',
    Currency: 'fkCurrencyCountry',
    Department: 'fkDept',
    EmployementType: 'fkEmploymentType',
    HigherEducation: 'fkHigherEdu',
    HiringManager: 'fkHiringMgr',
    Location: 'fkLocation',
    OfferApprover: 'fkOfferApprover',
    Recruiter: 'fkRecruiter',
    PayFrequency: 'fkSalaryPayFreq',
    SkillSet: 'fkSkillSet',
    RequisitionApprover: 'fkrequisitionApprover',
    ReplacementOrNew: 'isReplacement',
    Code: 'jobCode',
    StatusCode: 'statusCode',
    Library: 'fkJobLib',
    ContractStartDate: 'contractStartDate',
    ContractEndDate: 'contractEndDate',
    RoleId: 'fkCreatedByRoleId',
    ShiftSchedule: 'shiftSchedule'
};
export const NewJob = 'New Job';
export const NewCandidate= 'Add Candidate';

export const CandidateFormFieldNames = {
    Name: 'name',
    HomeLocation: 'fkHomeLocation',
    CurrentLocation: 'fkCurrentLocation',
    Source: 'fkHiringSource',
    Reference: 'fkReference',
    Language: 'fkLanguage',
    HigherEdu: 'fkHigherEdu',
    Code: 'candidateCode'
};

export const CandidateDetails = 'candidate-details';
export const AllJob = 'access-all-job';
export const JobRequisition = 'job-requisition';
export const CreateJob = 'create-job';
export const AllCandidate = 'access-all-candidate';
export const OfferApprover = 'offer-approval';
export const InterviewSchedule = 'interview-schedule';
export const CandidateAssessmentTab = 'candidate-assessment';

export const JobStatusCode= {
    Draft: 'DF',
    Save: 'SV',
    Publish: 'PU',
    Approved: 'AP',
    SendBack: 'SB',
    Closed: 'CL',
    Rejected: 'RJ',
    Reactivated: 'RE',
};
export const JobStatusKey = 'jobRequisitionStatusCode';

export const clearFilterMessage = 'Filter removed; showing all candidates';

export const InterviewFormFields = {
    Interviewer: 'fkInterviewer',
    InterviewType: 'fkInterviewtype',
    modeOfInterview: 'fkInterviewMode',
    InterviewDateTime: 'interviewTimeUtc',
    InterviewStatus: 'statusCode'
};

export const InterviewStatusCode = {
    NextRound: 'NR',
    Selected: 'SL',
    Rejected: 'RJ',
    OnHold: 'OH'
};

export const JobInterviewStatus = {
    InterviewSchedule: 'IS',
    FeedbackSubmitted: 'RSU'
};