export const claimsHeadCell = (viewAccess) => {
  return [
    {
      id: 'referenceNumber',
      label: 'REFERENCE NUMBER',
    },
    {
      id: 'name',
      label: 'CLAIMANT',
      hide: !viewAccess.my_access
    },
    {
      id: 'dept',
      label: 'DEPARTMENT',
    },
    {
      id: 'type',
      label: 'CLAIM TYPES',
    },
    {
      id: 'createdAt',
      label: 'SUBMISSION DATE',
    },
    {
      id: 'amount',
      label: 'AMOUNT',
      numeric: true
    },
    {
      id: 'payDate',
      label: viewAccess.paymode?'PAYMENT MONTH':'PAYMENT DATE',
      hide: viewAccess.my_access
    },
    {
      id: 'status',
      label: 'STATUS',
    },
  ];};