import {
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import ButtonUI from '../../shared/components/ui-elements/ButtonUI';
import Loading from '../../atoms/Loading/loading';
import ReasonIssueList from '../../constants/Reasons';
import * as Joi from 'joi';
import { ReactComponent as SuccessIcon } from '../../assets/SuccessIcon.svg';
import Modal from '../../shared/components/ui-elements/Modal';
import ModalDialog from '../../atoms/Modal/ModalDialog';
// import Modal from '../../ui-elements/Modal';

export default function ClaimRejectModal({
  open,
  setOpen,
  claimDetails = {},
  claims,
  handleSubmit,
  onSuccessClose= ()=>{},
 }) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [input, setInput] = useState({
    remark: '',
    reasons: [],
    status: '',
    // status: 'RE-SUBMIT',
  });
  const [formErrorMessage, setFormErrorMessage] = useState(
   false
  );
  const [formSuccessMessage, setFormSuccessMessage] = useState(false);

  const schmea = {
    remark: Joi.string().required().label('Remark').allow(''),
    reasons: Joi.array()
      .items(Joi.string().valid(...ReasonIssueList))
      .label('Reasons')
      .min(1)
      .required(),
    status: Joi.string().required().label('Allowed | Not Allowed'),
  };
  const submit = async () => {
    setFormErrorMessage(false);
    setFormSuccessMessage(false);
    setLoading(true);
    const { error } = Joi.object(schmea).validate(input, { abortEarly: false });
    if (error) {
      console.log('error', error);
      console.log('error', error.details);
      let err = {};
      error?.details?.forEach((item) => {
        err[item.context.key] =
          item.type === 'array.min'
            ? `Please select any ${item.context.label}`
            : item.message;
      });
      setErrors(err);
      setLoading(false);
      return;
    }
    try {
      const { success, error: err } = await handleSubmit({
        ...input,
        claims: claims || [claimDetails.id],
      });
      if (success) {
        setFormSuccessMessage(success);
        setOpen(false);
      }
      if (err) {
        setFormErrorMessage(err);
      }
    } catch (error) {
      console.log(error);
    }
    
    setLoading(false);

  };

  let headerContent = (
    <React.Fragment>
      <div className="ids-wrap">
        {claimDetails.employee && (
          <React.Fragment>
            <div className="emp-id-wrap">
              Employee ID: <b>{claimDetails.employee.id}</b>
            </div>
            <div className="ref-number-wrap">
              <span>Claim Reference Number: {claimDetails.refNumber}</span>
            </div>
          </React.Fragment>
        )}
      </div>
      <div className="request-details-wrap">
        <div className="request-info">
          <h4 style={{ textTransform: 'capitalize' }}>
            {claimDetails && claimDetails.claimType?.name}
          </h4>
          <h5>
            {/* {employeeClaimData[0].fromDest &&
                      `Destination:${employeeClaimData[0].fromDest} to ${employeeClaimData[0].toDest}`} */}
          </h5>
        </div>
        <div className="request-amount">
          {claimDetails && (
            <h5>Amount : ${Number(claimDetails.amount).toFixed(2)}</h5>
          )}
        </div>
      </div>
    </React.Fragment>
  );

  const handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    const { error } = schmea[name].validate(value);
    const err = { ...errors };
    if (error) {
      err[name] = error.message;
    } else {
      delete err[name];
    }
    setErrors(err);
    setInput({ ...input, [name]: value });
  };

  const handleSuccessClose = ()=>{
    setFormSuccessMessage(false);
    onSuccessClose();
  };

  return (
    <>
      {formSuccessMessage ? (
        <>
          <ModalDialog
            showModal={formErrorMessage}
            onClickClose={()=>handleSuccessClose}
            primaryAction={'Close'}
            onClickConfirm={()=>handleSuccessClose}
            title={'Success'}
            description={formSuccessMessage}
          />
        </>
      ) : (
        <Modal
          show={open}
          onCancel={() => setOpen(false)}
          header={headerContent}
          contentClass="modal-content"
          footerClass="footer-content"
          submitButton={
            <div>
              <Loading loading={loading}>
                <ButtonUI
                  backgroundColor="#ffffff"
                  color="#00AC94"
                  size="small"
                  padding="3px 20px"
                  border=" 1px solid #00AC94"
                  width="134px"
                  margin="0 10px 0 0"
                  font="normal normal bold Plus Jakarta Sans"
                  onclick={() => setOpen(false)}
                >
                  Back
                </ButtonUI>
                <ButtonUI
                  backgroundColor="#00AC94"
                  color="#ffffff"
                  size="small"
                  padding="3px 20px"
                  width="134px"
                  font="normal normal bold Plus Jakarta Sans"
                  onclick={submit}
                >
                  Submit
                </ButtonUI>
              </Loading>
              {errors.status && (
                <Typography padding={'10px'} variant="body2" color={'red'}>
                  {errors.status}
                </Typography>
              )}
            </div>
          }
        >
          <>
            {formErrorMessage && (
              <Typography
                paddingBottom={'30px'}
                textAlign={'center'}
                variant="body2"
                color={'red'}
              >
                {formErrorMessage}
              </Typography>
            )}
            <div className="send-back-content-wrap">
              <h4>Select Submission</h4>
              <div className="radio-wrap">
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="status"
                    value={input.status}
                    onChange={handleChange}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <FormControlLabel
                      value="RE-SUBMIT"
                      control={<Radio />}
                      label="Allow Resubmission"
                    />
                    <FormControlLabel
                      value="REJECTED"
                      control={<Radio />}
                      label="Don’t Allow Resubmission"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {errors.status && (
                <Typography variant="body2" color={'red'}>
                  {errors.status}
                </Typography>
              )}
              <h4>Select Reasons</h4>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  marginBottom: '20px',
                }}
              >
                {ReasonIssueList.map((item, index) => {
                  return (
                    <Chip
                      key={index}
                      avatar={
                        input?.reasons?.includes(item) ? <SuccessIcon />:<></>
                      }
                      label={item}
                      style={{
                        backgroundColor: input?.reasons?.includes(item)
                          ? '#D9F3EF'
                          : '#FFFFFF',
                        border: input?.reasons?.includes(item)
                          ? ''
                          : '1px solid #00AC94',
                        color: '#0E8978',
                        marginRight: '10px',
                        marginTop: '10px',
                      }}
                      onClick={() =>
                        handleChange({
                          target: {
                            name: 'reasons',
                            value: input?.reasons?.includes(item)
                              ? input.reasons.filter((i) => i != item)
                              : [...input.reasons, item],
                          },
                        })
                      }
                    />
                  );
                })}
                {errors.reasons && (
                  <Typography padding={'10px'} variant="body2" color={'red'}>
                    {errors.reasons}
                  </Typography>
                )}
              </div>

              <h4>Remark</h4>
              <TextField
                id="outlined-multiline-flexible"
                multiline
                rows={5}
                maxRows={10}
                value={input.remark}
                name="remark"
                fullWidth
                onChange={handleChange}
                error={errors.remark ? true : false}
                helperText={errors.remark}
              />
            </div>
          </>
        </Modal>
      )}
    </>
  );
}
