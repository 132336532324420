import { Box, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import ActionHeader from '../../../../../../OnBoarding/Organization/ActionHeader/ActionHeader';
import FormView from '../../../../../../OnBoarding/common/Form/FormView';
import {
  AllJob,
  JobFormFieldNames,
  JobRequisition,
  JobStatusKey,
  NewJob,
} from '../../../../../Constant/constant';
import { permissionsObj } from '../../../../../../OnBoarding/Organization/RolesAndPermissions/constants/roles-permissions.constants';
import ApproverFooter from '../../../../../../common/ApproverFooter/ApproverFooter';
import ModalDialog from '../../../../../../../atoms/Modal/ModalDialog';
import { JobServices } from '../../../../../../../services/jobRecuriting';
import SendBackRecrutingFrom from './SendBackRecrutingFrom';
import AuditLogs from '../../../../../../audit-logs/audit-logs';
import Loading from '../../../../../../../atoms/Loading/loading';
import { getEditOnStatus } from '../../../../../utils/recruitingUtils';
import ApprovalWorkflowFooterCommon from '../../../../../../OnBoarding/Organization/ApprovalFlow/Footer/ApprovalWorkflowFooterCommon';
import AuditLogApprovalWorkflow from '../../../../../../OnBoarding/Organization/ApprovalFlow/ApprovalWorkflowUtilsCommon/Audit_log/AuditLogApprovalWorkflow';
import { RequisitionReasonList } from '../../../../../../../constants/Reasons';
import { useNavigate } from 'react-router-dom';
import { PayrollStatus } from '../../../../../../Payroll/Constant/Constant';
import ApprovalWorkflowAuditLog from '../../../../../../OnBoarding/Organization/ApprovalFlow/ApprovalWorkflowUtilsCommon/ApprovalWorkflowAuditTrail';

export default function ViewNewJob(props) {
  const { setViewMode, jobConfig } = props;
  const {
    jobFormData,
    isReqApprover,
    logs: activeLogs,
    auditTrail,
    approvalFlow,
  } = useSelector((state) => state.job);
  const { recrutingPermissions } = useSelector(
    (state) => state.modulePermissions
  );
  console.log(recrutingPermissions?.[JobRequisition], jobFormData);
  const { currentUserRoleId } = useSelector((state) => state.main);
  const [isAuthorized, setisAuthorized] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const navigate = useNavigate();

  const saveDetails = useCallback(async (action) => {
    switch (action) {
      case PayrollStatus.Approved: {
        new JobServices().updateJobStatus({
          action: 'APPROVED',
          jobId:Number(jobFormData.id),
        });
        break;
      }
      case PayrollStatus.Rejected: {
        new JobServices().updateJobStatus({
          action: 'REJECTED',
          jobId:Number(jobFormData.id),
        });
        break;
      }
      case PayrollStatus.SendBack: {
        new JobServices().updateJobStatus({
          action: 'RE-SUBMIT',
          jobId:Number(jobFormData.id),
        });
        break;
      }
      case PayrollStatus.Pending: {
        new JobServices().updateJobStatus({
          action: 'PENDING',
          jobId:Number(jobFormData.id),
        });
        break;
      }
    }
  }, []);

  return (
    <Box>
      <Box sx={{ flexGrow: 1 }}>
        <Box mb={3} mx={-3} mt={-3}>
          <ActionHeader
            labelText={jobFormData?.[JobFormFieldNames.Title]?.name || NewJob}
            editButtonText="Edit"
            onEditClick={() => setViewMode(false)}
            labelCode={
              jobFormData?.[JobFormFieldNames.Code]
                ? jobFormData?.[JobFormFieldNames.Code]
                : ''
            }
            showSave={getEditOnStatus(
              recrutingPermissions,
              jobFormData?.[JobFormFieldNames.StatusCode]?.[JobStatusKey],
              isEditable
            )}
          />
        </Box>
        <Grid container rowSpacing={3} columnSpacing={3}>
          <Grid item md={7}>
            <FormView list={jobConfig} info={jobFormData} hideFields={false} />
          </Grid>
          <Grid item md={5}>
            <Box
              ml={6}
              pl={6}
              sx={{ borderLeft: 'solid 1px', borderColor: 'neutral.dark40' }}
              mb={3}
            >
              <ApprovalWorkflowAuditLog auditTrail={auditTrail} defaultTimezone="" />
            </Box>
          </Grid>
          <Grid item md={12}>
            <ApprovalWorkflowFooterCommon
              recordId={jobFormData?.id}
              moduleId={recrutingPermissions?.[JobRequisition]?.id}
              reasons={RequisitionReasonList}
              refId={''}
              setIsEditable={setIsEditable}
              saveDetails={saveDetails}
              handleApprove={() => navigate(-1)}
              claimRaisingFor={undefined}
              isAuthorized={isAuthorized}
              setisAuthorized={setisAuthorized}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
