export const entitlementsConfigs = [
  {
    label: 'Start Date',
    getter: (details) => details.startDate,
    required: false,
  },
  {
    label: 'End Date',
    getter: (details) => details.endDate,
    required: false,
  },
  {
    label: 'Entitlement',
    getter: (details, currencySymbol) =>
      details.entitlementAmt
        ? `${currencySymbol} ${details.entitlementAmt}`
        : '',
    required: false,
  },
  {
    label: 'Balance',
    getter: (details, currencySymbol) =>
      details.entitlementBalanceAmt
        ? `${currencySymbol} ${details.entitlementBalanceAmt}`
        : '',
    required: false,
  },
];
