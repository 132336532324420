import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LoadingStatus } from '../../../../constants/common';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { InfoOutlined, PersonOutline } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';
import ActionHeader from '../../Organization/ActionHeader/ActionHeader';
import { UserStatus } from '../../Organization/utils/userOnboarding/userOnboarding.util';
import { OnboardingServices } from '../../../../services/onboarding';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { getSGTTimeZone } from '../../../../utils/formatter';

const MyJourneyDashboard = (props) => {
  const { empId, userName, status } = props;
  const { currentUserRoleId, userInfo: loggedInUserDetails } = useSelector(
    (state) => state.main
  );
  const navigate = useNavigate();

  const [myJourneyList, setMyJourneyList] = useState([]);
  const [loading, setLoading] = useState(-1);

  useEffect(async () => {
    if (currentUserRoleId && empId) {
      setLoading(LoadingStatus.Loading);
      const response = await new OnboardingServices()
        .getAllMyJourney(empId)
        .finally(() => {
          setLoading(LoadingStatus.Success);
        });
      setMyJourneyList(response || {});
    }
  }, [currentUserRoleId, empId]);

  const rowData = myJourneyList?.map((items) => {
    const { id, event, oldValue, newValue, linkedModuleName, changedBy } =
      items;
    return {
      event,
      oldValue,
      newValue,
      changedBy: changedBy?.displayName,
      action:
        linkedModuleName === 'separation' ? (
          <IconButton
            onClick={() => navigate({ pathname: `/my-journey/${empId}` })}
          >
            <InfoOutlined sx={{ color: 'primary.main80' }} />
          </IconButton>
        ) : (
          { hide: true }
        ),
    };
  });

  const handleNavigation = (linkedModuleName) => {
    if (linkedModuleName === 'separation') {
      navigate({ pathname: `/my-journey/${empId}` });
    }
  };

  return (
    <>
      <CircularLoader show={loading === LoadingStatus.Loading} />
      <Box mb={3} mx={-3} mt={-3}>
        <ActionHeader
          labelText={userName || 'New User'}
          showPublish={false}
          showPublishModal={false}
          showSave={false}
        />
      </Box>
      <Timeline position="alternate">
        {myJourneyList?.map((items, index) => {
          const {
            id,
            event,
            oldValue,
            newValue,
            linkedModuleName,
            changedBy,
            changedAt,
          } = items;
          return (
            <TimelineItem key={index}>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                <Typography>{changedBy?.displayName}</Typography>
                <Typography>
                  {getSGTTimeZone(changedAt, 'DD/MM/YYYY')}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot>
                  <PersonOutline />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography
                  variant="subtitle2"
                  component="span"
                  sx={{ cursor: linkedModuleName === 'separation' ? 'pointer' : 'default' }}
                  onClick={() => linkedModuleName === 'separation' ? handleNavigation(linkedModuleName) : null}
                >
                  {event}
                </Typography>
                <Typography>
                  {oldValue} {'-->'} {newValue}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
      <Box mb={3} mt={1}>
        {loggedInUserDetails?.id == empId && (
          <Button
            variant="contained"
            size="medium"
            onClick={() => {
              navigate('/separation/resign');
            }}
            disabled={status === UserStatus.Resign}
          >
            Resign
          </Button>
        )}
      </Box>
    </>
  );
};

export default MyJourneyDashboard;
