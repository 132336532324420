import React from 'react';
import { Grid } from '@mui/material';
import ButtonUI from '../ui-elements/ButtonUI';
import { useNavigate } from 'react-router-dom';
import './DraftOptions.css';


const DraftOptions = ({currentUserType,currentUserData,claimType,deleteDraft,editData,submitData}) => {
const navigate = useNavigate();

    return (
        <Grid container className='draft-options-wrap claims-data'>
        <Grid className='draft-options-inner-wrap' style={{
          padding: '20px 40px',
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }} xs={12} sm={12} md={12} lg={12}>
          {currentUserType == 'Employee' &&
          (currentUserData.singleData.status == 'DRAFT' || currentUserData.singleData.status == 'RE-SUBMIT') && (
            <React.Fragment>
              <div style={{ display: 'none' }}>
                <ButtonUI
                  backgroundColor='#00AC94'
                  color='#ffffff'
                  size='small'
                  padding='3px 20px'
                  margin='0 10px 0 0 '
                  onclick={submitData}
                >
                  Submit
                </ButtonUI>
              </div>

              <ButtonUI
                backgroundColor='#00AC94'
                color='#ffffff'
                size='small'
                padding='3px 20px'
                margin='0 10px 0 0 '
                onclick={editData}
              >
                Edit
              </ButtonUI>
              <ButtonUI
                backgroundColor='#00AC94'
                color='#ffffff'
                size='small'
                padding='3px 20px'
                margin='0 10px 0 0 '
                onclick={deleteDraft}
              >
                Delete
              </ButtonUI>
            </React.Fragment>
          )}

        {currentUserType == 'Employee' && (
          <ButtonUI
            backgroundColor='#00AC94'
            color='#ffffff'
            size='small'
            onclick={() =>
              navigate('/dashboard', {
                state: {
                  claimType: claimType.toString(),
                },
              })
            }
          >
            Go Back
          </ButtonUI>
        )}
              </Grid>
              </Grid>
    );
};

export default DraftOptions;