import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { SubModuleConfigure } from './subModuleConfigure';
import AccordionTabs from '../../../../../atoms/Accordian/Accordian';
import { useDispatch, useSelector } from 'react-redux';
import { getRolesConfig, resetRolesConfig, updateRolePermissions } from '../../../../../redux/rolesAndPermissions/action';
import CircularLoader from '../../../../../atoms/CircularLoader/circular-loader';
import { APIRequestStatus, LoadingStatus, ToastThemes } from '../../../../../constants/common';
import { isEmpty } from 'lodash';
import { showToast } from '../../../../../utils/common.util';
import { ToastMessages, stringSubstitute } from '../../constants/onboarding.constants';
import ActionHeader from '../../ActionHeader/ActionHeader';

export const EditSettings = (props) => {

  const { roleConfig, setShowConfigure, onClickDiscard } = props;
  const { roleName, id: roleId } = roleConfig;
  const dispatch = useDispatch();
  const { roleConfig: rolePermissions, requestProcessing } = useSelector((state) => state.rolesAndPermissions);
  const { [APIRequestStatus.GET_ROLE_CONFIG]: roleConfigLoad,
    [APIRequestStatus.UPDATE_ROLE_PERMISSIONS]: updatePermissionsLoad
  } = requestProcessing;
  const [modulePermissions, setModulePermissions] = useState([]);

  useEffect(() => {
    if (updatePermissionsLoad === LoadingStatus.Success) {
      setShowConfigure(false);
      showToast(ToastMessages.success.replace(stringSubstitute, 'Saved'), ToastThemes.success);
    } else if (updatePermissionsLoad === LoadingStatus.Failure) {
      showToast(ToastMessages.failed.replace(stringSubstitute, 'save'), ToastThemes.error);
    }
  }, [updatePermissionsLoad]);
  useEffect(() => {
    if (!rolePermissions) {
      dispatch(getRolesConfig(roleId));
    }
    return () => dispatch(resetRolesConfig());
  }, []);

  return (
    <Box>
      <Box mb={3} mx={-3} mt={-3}>
        <ActionHeader
          labelText={roleName}
          showDiscard={true}
          editButtonText='Save'
          onEditClick={() => dispatch(updateRolePermissions(roleId, modulePermissions))}
          disabledSave={isEmpty(modulePermissions)}
          onClickDiscard={onClickDiscard}
        />
      </Box>
      <CircularLoader show={roleConfigLoad === LoadingStatus.Loading} />
      {
        rolePermissions?.map((item) => {
          const { id, name, child, displayName } = item;
          return (
            <AccordionTabs
              key={id}
              title={displayName}
              tabDetails={
                <SubModuleConfigure
                  content={child}
                  setModulePermissions={setModulePermissions}
                  parentName={name}
                  roleId={roleId}
                  modulePermissions={modulePermissions}
                  moduleId={id}
                  roleName={roleName}
                />} />
          );
        })
      }
    </Box>
  );
};