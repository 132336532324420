import { Box, Divider, Grid } from '@mui/material';
import React from 'react';  
import SearchBar from '../../atoms/SearchBar/SearchBar';
import CustomBreadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs';
import ClaimList from './ClaimList';
import ClaimTabs from './ClaimTabs';
import ClaimRHS from '../claim-rhs/claim-rhs';
import { useSelector } from 'react-redux';

export default function Claims() {

  const { appPermissions } = useSelector((state) => state.main);

  return (
    <div className='main-dashboard-wrap'>
      <div className={'left-content'}>
      <Box m={4} pl={4}>
        <Box sx={{ display: 'flex', gap: 7 }}>
        <SearchBar />
        </Box>

        <Box mt={3}>
          <CustomBreadcrumbs
          tabs={
            [{
              tabName: 'Claims',
              url: '/claims'
            }
          ]}
            itemName={{
              itemA: 'Claims',
              itemB: 'Transpoart',
              // itemZ: 'Org Tree',
            }}
            itemURL={{ 
              itemA: '/claims', 
              itemB: '/', 
              // itemZ: '#' 
          }}
          />
          <Box mt={1}>
            <Divider />
          </Box>
        </Box>

        <Box mt={4}>
          <ClaimTabs />
        </Box>
      </Box> 
      </div>
      {appPermissions && appPermissions['raise-claims'] && (<ClaimRHS />)}
    </div>
  );
}
