import moment from 'moment';
import { DateFormatters } from '../constants/dateConstants';
import * as momentTimezone from 'moment-timezone';

export const getDate = (value, format) => {
  if (format) {
    return moment(value, format);
  }
  return moment(value, DateFormatters.ddmmyyyy);
};

export const getFormattedValues = (format, date) => {
  return moment(date).format(format);
};

export const getUtcWithoutFormatFrom = (date, format) => {
  return moment.utc(date).format(format);
};
export const getYearFromCurrentDate = (val) => {
  return moment().diff(moment(val, DateFormatters.ddmmyyyy), 'years');
};

export const getTime = (value) => {
  return moment(value, 'hh:mm A');
};

export const formattedTimeValue = (time, format) => {
  return moment(time).format(format);
};

export const formattedDateTimeValue = (time) => {
  return moment(time).utc().format();
};
export const formatUtcDate = (date, format) => {
  return moment(date).utc().format(format);
};

export const getDateTime = (value, dataInputFormat) => {
  return moment(value, dataInputFormat);
};

export const getFormattedDate = (value, format, displayFormat) => {
  if (`${value}`.length === 5) {
    return moment(`0${value}`, format).format(displayFormat);
  }
  return moment(value, format).format(displayFormat);
};

export const getFormattedStartOrEndDateForCurrent = (
  date,
  startOf,
  isStart = false,
  formatTo
) => {
  if (isStart) {
    return moment(date).startOf(startOf).format(formatTo);
  }
  return moment(date).endOf(startOf).format(formatTo);
};

export const getFormattedStartOrEndDateForLastMonthFromGivenDate = (
  date,
  startOf,
  isStart = false,
  formatTo
) => {
  if (isStart) {
    return moment(date).startOf(startOf).format(formatTo);
  }
  return moment(date).endOf(startOf).format(formatTo);
};

export const getFormattedStartOrEndDateForAnyPastMonthFromCurrent = (
  pervMonth,
  startOf,
  isStart = false
) => {
  if (isStart) {
    return moment().subtract(pervMonth, 'months').startOf(startOf);
  }
  return moment().subtract(pervMonth, 'months').endOf(startOf);
};

export const currentDate = moment();

export const firstDateOfCurrentMonth = moment().startOf('month');

export const lastDateOfCurrentMonth = moment().endOf('month');

export const noOfmonthfromCurrent = (pervMonth) => {
  return moment().subtract(pervMonth, 'months');
};

export const firstDayOfMonth = moment().startOf('month').add(1, 'months');

export const getUtcToLocal = (dateReceived, formatFrom, formatTo) => {
  return moment.utc(dateReceived, formatFrom).local().format(formatTo);
};

export const isDateSame = (value, targetValue) => {
  return moment(value).isSame(targetValue);
};

export const isDateBetween = (value, range) => {
  if (range && value) {
    const [min, max] = range;
    return moment(value).isBetween(min, max, undefined, '[]');
  }
  return false;
};

export const isDateAfter = (value, targetVal) => {
  if (targetVal && value) {
    return moment(value).isAfter(targetVal);
  }
  return false;
};

export const isDateBefore = (value, targetVal) => {
  if (targetVal && value) {
    return moment(value).isBefore(targetVal);
  }
  return false;
};

export const shortcutsItems = {
  currentMonth: {
    label: 'Current Month',
    getValue: () => {
      const today = () => moment();
      return [today().startOf('month'), today().endOf('month')];
    },
  },
  nextMonth: {
    label: 'Next Month',
    getValue: () => {
      const today = () => moment();
      const startOfNextMonth = () => today().endOf('month').add(1, 'day');
      return [startOfNextMonth(), startOfNextMonth().endOf('month')];
    },
  },
  lastMonth: {
    label: 'Last Month',
    getValue: () => {
      const today = () => moment();
      const endOfLastMonth = () => today().startOf('month').subtract(1, 'day');
      return [endOfLastMonth().startOf('month'), endOfLastMonth()];
    },
  },
  lastYear: {
    label: 'Last Year',
    getValue: () => {
      const today = () => moment();
      const startOfLastYear = () =>
        today().startOf('year').subtract(1, 'years');
      const endOfLastYear = () => today().endOf('year').subtract(1, 'year');
      return [startOfLastYear(), endOfLastYear()];
    },
  },
  Q1: {
    label: 'First Quarter',
    getValue: () => {
      const today = () => moment();
      const startOfFirstQuarter = () => today().startOf('year');
      const endOfFirstQuarter = () =>
        startOfFirstQuarter().add(2, 'months').endOf('month');
      return [startOfFirstQuarter(), endOfFirstQuarter()];
    },
  },
  Q2: {
    label: 'Second Quarter',
    getValue: () => {
      const today = () => moment();
      const startOfSecondQuarter = () =>
        today().startOf('year').add(3, 'months');
      const endOfSecondQuarter = () =>
        startOfSecondQuarter().add(2, 'months').endOf('month');
      return [startOfSecondQuarter(), endOfSecondQuarter()];
    },
  },
  Q3: {
    label: 'Third Quarter',
    getValue: () => {
      const today = () => moment();
      const startOfThirdQuarter = () =>
        today().startOf('year').add(6, 'months');
      const endOfThirdQuarter = () =>
        startOfThirdQuarter().add(2, 'months').endOf('month');
      return [startOfThirdQuarter(), endOfThirdQuarter()];
    },
  },
  Q4: {
    label: 'Fourth Quarter',
    getValue: () => {
      const today = () => moment();
      const startOfFourthQuarter = () =>
        today().startOf('year').add(9, 'months');
      const endOfFourthQuarter = () =>
        startOfFourthQuarter().add(2, 'months').endOf('month');
      return [startOfFourthQuarter(), endOfFourthQuarter()];
    },
  },
  default: {
    label: 'Reset',
    getValue: () => [null, null],
  },
};

export const isTimeSame = (value, targetValue) => {
  const moment1 = moment(value, 'hh:mm a');
  const moment2 = moment(targetValue, 'hh:mm a');
  return moment1.isSame(moment2);
};

export const isTimeAfter = (value, targetVal) => {
  if (targetVal && value) {
    const moment1 = moment(value, 'hh:mm a');
    const moment2 = moment(targetVal, 'hh:mm a');
    return moment1.isAfter(moment2);
  }
  return false;
};

export const isTimeBefore = (value, targetVal) => {
  if (targetVal && value) {
    const moment1 = moment(value, 'hh:mm a');
    const moment2 = moment(targetVal, 'hh:mm a');
    return moment1.isBefore(moment2);
  }
  return false;
};

export const isTimeSameOrAfter = (value, targetVal) => {
  if (targetVal && value) {
    const moment1 = moment(value, 'hh:mm a');
    const moment2 = moment(targetVal, 'hh:mm a');
    return moment1.isSameOrAfter(moment2);
  }
  return false;
};

export const isTimeSameOrBefore = (value, targetVal) => {
  if (targetVal && value) {
    const moment1 = moment(value, 'hh:mm a');
    const moment2 = moment(targetVal, 'hh:mm a');
    return moment1.isSameOrBefore(moment2);
  }
  return false;
};

export const isDateTimeAfter = (value, targetVal) => {
  if (targetVal && value) {
    const moment1 = moment(value, 'YYYY-MM-DD hh:mm a');
    const moment2 = moment(targetVal, 'YYYY-MM-DD hh:mm a');
    return moment1.isAfter(moment2);
  }
  return false;
};

export const isDateTimeSame = (value, targetValue) => {
  const moment1 = moment(value, 'YYYY-MM-DD hh:mm a');
  const moment2 = moment(targetValue, 'YYYY-MM-DD hh:mm a');
  return moment1.isSame(moment2);
};

export const isDateTimeBefore = (value, targetVal) => {
  if (targetVal && value) {
    const moment1 = moment(value, 'YYYY-MM-DD hh:mm a');
    const moment2 = moment(targetVal, 'YYYY-MM-DD hh:mm a');
    return moment1.isBefore(moment2);
  }
  return false;
};

export const utcToLocal = (dateReceived, formatFrom, formatTo) => {
  return moment(dateReceived, formatFrom)
    .utc(dateReceived, formatFrom)
    .local()
    .format(formatTo);
};

export const getSGTTimeZone = (date, format, timeZone) => {
  if (timeZone === 'Asia/Singapore') {
    return moment.utc(date).utcOffset('+08:00').format(format);
  } else {
    return moment.utc(date).format(format);
  }
};

export const checkForValidDateForIsoFormat = (value) => {
  return moment(value, 'YYYY-MM-DD', true).isValid();
};

export const getTimeDifferenceMilliseconds = (startTime, endTime) => {
  const momentObjStartTime = moment(startTime, 'hh:mm a');
  const momentObjEndTime = moment(endTime, 'hh:mm a');
  const millisecondsDiffirence = momentObjEndTime.diff(momentObjStartTime);
  return millisecondsDiffirence;
};

export const getCalendarDay = (date, day) => {
  return moment(date).day() === day;
};

export const getPaymentDate = (defaultTimezone, claimCycleDates) => {
  const currentDateMoment = momentTimezone().tz(defaultTimezone);
  const currentDate = currentDateMoment.date();
  const currentMonth = currentDateMoment.month();
  const currentYear = currentDateMoment.year();
  const paymentDay = claimCycleDates?.claimsCyclePaymentDay;
  const payCycleDate = claimCycleDates?.claimsCycleCloseByDay;
  let paymentDate;
  if (currentMonth === 12 && currentDate > payCycleDate) {
    // If current month is December, payment will be in January of next year
    // for currentdate < paycycleDate
    paymentDate = momentTimezone.tz(
      [currentYear + 1, 1, paymentDay],
      defaultTimezone
    );
  } else {
    // For other months, calculate payment month based on the approval date
    const paymentMonth =
      currentDate <= payCycleDate ? currentMonth : currentMonth + 1;
    paymentDate = momentTimezone.tz(
      [currentYear, paymentMonth, paymentDay],
      defaultTimezone
    );
  }
  return paymentDate;
};

export const isAnyOfDays = (days, currentDay) => {
  if (days.includes(currentDay)) return true;
  return false;
};

export const utcToLocalMomentObj = (dateReceived, formatFrom) => {
  return moment(dateReceived, formatFrom).utc(dateReceived, formatFrom).local();
};

export const checkForValidDate = (value) => {
  return moment(value, 'DD/MM/YYYY', true).isValid();
};