export const BuilderAddFieldNames = {
    Text: 'text',
    Number: 'number',
    Email: 'email',
    SingleSelect: 'singleSelect',
    MultiSelect: 'multiSelect',
    Checkbox: 'checkbox',
    Date: 'date',
    Time: 'time',
    DateAndTime: 'dateAndTime',
    FileUpload: 'fileUpload',
    MultiLineText: 'multiLineText',
    Formula: 'formula',
    DateRange: 'dateRange',
    AutoPopulate: 'autoPopulate',
    MultiFileUpload: 'multiFileUpload',
    Month: 'month',
    UserBasedSSL: 'userBasedSSL',
    UserBasedField: 'userBasedField',
    Public: 'public',
    Off: 'off',
    Rest: 'rest',
    EntitlementAutoPopulate: 'entitlementAutoPopulate',
    ProRateField: 'proRateField',
    CurrencyExchangeField: 'currencyExchangeField',
    Year: 'year'
};

export const ModalConfigFieldName = {
    FieldRefName: 'sysField',
    FieldSize:'fieldSize',
    ValidationOption: 'option',
    UnqiueValidationName: 'validationOption',
    DataFormat: 'percisionNumber',
    FieldLabel: 'fieldLabel',
    ThousandSeparator: 'isCurrencyFormater',
    MinMinutesNextClaim: 'timeInMinutes',
    TimeErrorMessage: 'errorMessage',
    DateFormat: 'dateFormat',
    RemoveDecimal: 'removeDecimal',
    ToUpperCase: 'toUpperCase',
    MaxLength: 'maxLength'
};