import React, { useEffect, useState } from 'react';

import {
  Chip,
  TextField,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
} from '@mui/material';

import Modal from '../ui-elements/Modal';
import ButtonUI from '../ui-elements/ButtonUI';
import { ReactComponent as SuccessIcon } from '../../../assets/SuccessIcon.svg';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    '&.MuiButtonBase-root': {
      '&.Mui-checked': {
        color: '#00ac94',
      },
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#00ac94',
      },
    },
    '&.MuiCheckbox-root': {
      color: 'rgba(0, 0, 0, 0.54)',
      '&.Mui-checked': {
        color: '#00ac94',
      },
    },
    '&.MuiTypography-body1': {
      fontSize: '14px',
    },
  },
});

const ClaimRejectModal = ({
  isBackdropOpen,
  closeBackdropHandler,
  employeeId,
  currentUserData,
  employeeClaimData,
  selectionList,
  selected,
  handleChipSelected,
  handleRejectRequest,
  remark,
  handleRemarkChange,
  multipleRejection,
  disabled = false,
  showClaimHeader= true
}) => {
  const [value, setValue] = useState('allow');

  const classes = useStyles();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  let finalStatus = '';
  if (value == 'allow') {
    finalStatus = 'RE-SUBMIT';
  } else {
    finalStatus = 'REJECTED';
  }

  const sendRejectRequestStatus = (status) => {
    // if(remark == '') {
    //   alert('Remark can not be empty !');
    //   return;
    // }
    handleRejectRequest(status);
  };

  useEffect(() => {
    setValue('allow');
  }, [isBackdropOpen]);

  let headerContent = showClaimHeader ? (
    <React.Fragment>
      <div className="ids-wrap">
        {multipleRejection && <h2>Multiple Rejection Request</h2>}
        {currentUserData && (
          <React.Fragment>
            <div className="emp-id-wrap">
              Employee ID: <b>{employeeId}</b>
            </div>
            <div className="ref-number-wrap">
              <span>Claim Reference Number: {currentUserData.refNumber}</span>
            </div>
          </React.Fragment>
        )}
      </div>
      <div className="request-details-wrap">
        <div className="request-info">
          <h4 style={{ textTransform: 'capitalize' }}>
            {currentUserData &&
              currentUserData.singleData.claimType.replaceAll('-', ' ')}
          </h4>
          <h5>
            {/* {employeeClaimData[0].fromDest &&
                  `Destination:${employeeClaimData[0].fromDest} to ${employeeClaimData[0].toDest}`} */}
          </h5>
        </div>
        <div className="request-amount">
          {employeeClaimData && (
            <h5>Amount : ${Number(employeeClaimData[0].amount).toFixed(2)}</h5>
          )}
        </div>
      </div>
    </React.Fragment>
  ):<></>;

  return (
    <Modal
      show={isBackdropOpen}
      onCancel={closeBackdropHandler}
      header={headerContent}
      contentClass="modal-content"
      footerClass="footer-content"
      submitButton={
        <div>
          <ButtonUI
            backgroundColor="#ffffff"
            color="#00AC94"
            size="small"
            padding="3px 20px"
            border=" 1px solid #00AC94"
            width="134px"
            margin="0 10px 0 0"
            font="normal normal bold Plus Jakarta Sans"
            onclick={closeBackdropHandler}
          >
            Back
          </ButtonUI>
          <ButtonUI
            backgroundColor="#00AC94"
            color="#ffffff"
            size="small"
            padding="3px 20px"
            width="134px"
            font="normal normal bold Plus Jakarta Sans"
            onclick={() => sendRejectRequestStatus(finalStatus)}
            disabled={disabled}
          >
            {disabled && (
              <CircularProgress
                style={{ color: 'white', margin: '2px' }}
                size={20}
              />
            )}
            Submit
          </ButtonUI>
        </div>
      }
    >
      <div className="send-back-content-wrap">
        <h4>Select Submission</h4>
        <div className="radio-wrap">
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <FormControlLabel
                value="allow"
                control={<Radio classes={classes} />}
                label="Allow Resubmission"
              />
              <FormControlLabel
                value="not-allow"
                control={<Radio classes={classes} />}
                label="Don’t Allow Resubmission"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <h4>Select Reasons</h4>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginBottom: '20px',
          }}
        >
          {selectionList.map((item, index) => {
            return (
              <Chip
                key={index}
                avatar={selected.has(item) ? <SuccessIcon /> : ''}
                label={item}
                style={{
                  backgroundColor: selected.has(item) ? '#D9F3EF' : '#FFFFFF',
                  border: selected.has(item) ? '' : '1px solid #00AC94',
                  color: '#0E8978',
                  marginRight: '10px',
                  marginTop: '10px',
                }}
                onClick={() => handleChipSelected(item)}
              />
            );
          })}
        </div>

        <h4>Remark</h4>
        <TextField
          id="outlined-multiline-flexible"
          multiline
          rows={5}
          maxRows={10}
          value={remark}
          fullWidth
          onChange={handleRemarkChange}
        />
      </div>
    </Modal>
  );
};

export default ClaimRejectModal;
