import API from './api';
import store from '../redux/store';
// http://localhost:4545/recruiting/job-requisition/draft
export class JobServices extends API {
  static baseURL = API.env.REACT_APP_BASE_API_LOCAL+'/recruiting/job-requisition/' || '';
  constructor() {
    super(JobServices.baseURL);
  }

  getJobDetails = async (roleId, jobId, fromLibrary) => {
    return this.responsify(await this.instance.get('details', {
      params: {
        id: jobId,
        roleId,
        fromLibrary
      }
    }));
  };

  postJobInfo = async (jobFormInfo) => (
    this.responsify(await this.instance.put('save', jobFormInfo)));

  getAllJobList = async (roleId, searchQuery, startDate, endDate, title) => (
    this.responsify(await this.instance.get('list-all', {params: {roleId, searchQuery, startDate, endDate, title}})));

  getDropdownOptions = async (fieldName) => (
    this.responsify(await this.instance.get(`lookup-details/${fieldName}`))
  );

  postJobInfoDraft = async (jobFormInfo) => (
    this.responsify(await this.instance.post('draft', jobFormInfo)));

  putApproval = async (approvalInfo) => (
    this.responsify(await this.instance.put('approval', approvalInfo)));

  publishJobForApproval = async (jobIds) => (
    this.responsify(await this.instance.put('publish', jobIds)));

  changeJobStatus = async (jobInfo) => (
    this.responsify(await this.instance.put('change-job-status', jobInfo)));

  updateJobStatus = async (body) => (
      this.responsify(await this.instance.put('update-job-status', body)));

}