import React from 'react';
import {
    TaskAltOutlined,
    HighlightOff,
    DynamicFeed,
    CheckCircleOutline,
    SwipeLeftOutlined
  } from '@mui/icons-material';
import BtnDropDown from '../../../../../atoms/Buttons/BtnDropDown';
import { JobFormFieldNames, JobStatusCode, JobStatusKey } from '../../../Constant/constant';
import { useSelector } from 'react-redux';

const DropDownActionForJob = (props) => {

    const { checkedItems, handlePublish, handleApprove, handleReject, isApproval, handleCloseJob, handleReopenJob } = props;
    const {jobIdPropertiesMap } = useSelector((state) => state.job);

    const jobBtnOptions = [
        {
          title: 'Publish',
          icon: <TaskAltOutlined sx={{ width: 20, height: 20 }} />,
          handleClick: handlePublish,
          disabled: !!checkedItems.find((item) => jobIdPropertiesMap?.[item]?.[JobFormFieldNames.StatusCode]?.[JobStatusKey] !== JobStatusCode.Save)
        },
        {
          title: 'Close',
          icon: <HighlightOff sx={{ width: 20, height: 20 }} />,
          handleClick: handleCloseJob,
          disabled: !!checkedItems.find((item) => {
            const jobStatus = jobIdPropertiesMap?.[item]?.[JobFormFieldNames.StatusCode]?.[JobStatusKey];
            return jobStatus === JobStatusCode.Rejected || jobStatus === JobStatusCode.Closed || jobStatus === JobStatusCode.Draft;
          } )
        },
        {
          title: 'Reopen',
          icon: <TaskAltOutlined sx={{ width: 20, height: 20 }} />,
          handleClick: handleReopenJob,
          disabled: !!checkedItems.find((item) => {
            const jobStatus = jobIdPropertiesMap?.[item]?.[JobFormFieldNames.StatusCode]?.[JobStatusKey];
            return jobStatus !== JobStatusCode.Closed;
          } )
        },
        {
          title: 'Job Posting Live (Coming soon)',
          icon: <DynamicFeed sx={{ width: 20, height: 20 }} />,
          disabled:true
        },
        {
          title: 'IJP Live (Coming soon)',
          icon: <DynamicFeed sx={{ width: 20, height: 20 }} />,
          disabled:true
        },
        {
          title: 'Referral live (Coming soon)',
          icon: <DynamicFeed sx={{ width: 20, height: 20 }} />,
          disabled:true
        },
        {
          title: 'Discard (Coming soon)',
          icon: <HighlightOff sx={{ width: 20, height: 20 }} />,
          disabled:true
        },
      ];

      const approvalaction = [
        {
          id: 1,
          title: 'Approve',
          icon: <CheckCircleOutline sx={{ width: 20, height: 20 }} />,
          handleClick: handleApprove
      },
      {
          id: 2,
          title: 'Send Back',
          icon: <SwipeLeftOutlined sx={{width: 20, height: 20}}/>,
          handleClick: handleReject
      },
      ];
    return (
      <>
        {!isApproval&&(<BtnDropDown BtnOptions={isApproval?approvalaction:jobBtnOptions} selectedOption={0} disabled={!checkedItems.length}
        />)}
        </>
    );
};

export default DropDownActionForJob;