import { Box, Grid, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FieldView from '../molecules/FieldView/FieldView';
import { ClaimServices } from '../../services/claims';
import moment from 'moment';
const UserInfoMeta = [
    {
      label: 'Name of Claimant',
      getter: (details)=> `${details?.firstName || ''} ${details?.middleName || ''} ${details?.lastName || ''}`,
      required: false,
    },
    {
      label: 'Employee ID',
      getter: (details)=> `${details?.employeeId}`,
      required: true,
    },
    // {
    //   label: 'Designation',
    //   value: 'Eng.',
    //   required: true,
    // },
    {
      label: 'Reporting Officer',
      getter: (details)=> `${details?.reportingTo?.firstName || ''} ${details?.reportingTo?.middleName || ''} ${details?.reportingTo?.lastName || ''}`,
      required: true,
    },
    {
      label: 'Cost Center',
      getter: (details)=>`${details?.costCentre?.name}`,
      required: true,
    },
    {
      label: 'Department',
      getter: (details)=> `${details?.department?.name || ''}`,
      required: true,
    },
    {
      label: 'HOD',
      getter: (details)=> `${details?.department?.managerId?.displayName || ''}`,
      required: true,
    },
    {
      label: 'Date of Joining',
      getter: (details)=> `${details?.dateOfJoining ? moment(details?.dateOfJoining).format('DD/MM/yyyy') : ''}`,
      required: true,
    },
  ];
  export default function ClaimedEmployeeDetails({employee}) {
  const claimService = new ClaimServices();
    const [employeeDetails, setEmployeeDetails] = useState({});

    // Get Employee Details.
    const getEmployeeDetails = ()=>{
      if(employee) {
        setEmployeeDetails(employee);
  
      }else {
        // call the api to set the value.
        claimService.getEmployeeDetails().then(res=>{
          if(res.data?.data){
            setEmployeeDetails(res.data?.data);
          }
        });
      }
    };

    useEffect(()=>{
        getEmployeeDetails();
    },[employee]);

  return (
    <Box>
      <Paper sx={{ flexGrow: 1, p: 4, my: 2 }}>
        <Grid container rowSpacing={3} columnSpacing={8} pr={8}>
          {UserInfoMeta.map(function (data) {
            return (
              <>
                <Grid item md={3}>
                  <FieldView
                    labelText={data.label}
                    labelValue={data.getter ? data.getter(employeeDetails) : employeeDetails[data.value] || ''}
                    required={data.required}
                  />
                </Grid>
              </>
            );
          })}
        </Grid>
      </Paper>
    </Box>
  );
}