import React, { useEffect, useState } from 'react';
import BenefitCalculationExpression from './benefit-calculation-expression.component';
import { FormFieldTypes } from '../../../../../../../constants/common';
import ApiSelectTag from '../../../../../../../atoms/Select/ApiSelectTag';

const BenefitCommonExpressionEditor = (props) => {
  const { setFormInfo, computeExpression, fieldList, configType } = props;
  const [value, setValue] = useState(computeExpression || '');

  useEffect(() => {
    setFormInfo(value);
  }, [value]);

  const handleFieldClick = (field) => {
    const { fieldColumnName } = field;
    setValue((prev) => {
      return `${prev || ''} info.${fieldColumnName}`;
    });
  };

  const handleFunctionClick = (field) => {
    const { refName } = field;
    setValue((prev) => {
      return `${prev || ''} ${refName}`;
    });
  };

  const handleCalculatorClick = (digit) => {
    setValue((prev) => {
      const lastChar = prev.charAt(prev.length - 1);
      const allowSpace =
        (isNaN(lastChar) && lastChar !== '.') ||
        (isNaN(digit) && digit !== '.');
      const newExpr = `${prev || ''}${allowSpace ? ` ${digit}` : `${digit}`}`;
      return newExpr;
    });
  };

  const handleBackClick = () => {
    setValue('');
  };

  const handleDropDown = (event) => {
    const { value, name } = event.target;
    setValue(value?.fieldColumnName);
  };

  switch (configType) {
    case 'startDate': {
      const newList = [
        {
          id: '01',
          fieldDisplayName: 'Calender Year',
          name: 'startOfYear',
          fieldType: 'DT',
          fieldDatatypeCode: 'DT',
          fieldColumnName: 'START_OF_YEAR',
        },
        ...fieldList.filter((item) => item.fieldType === FormFieldTypes.Date),
      ];
      return (
        <ApiSelectTag
          defaultValue={'Select'}
          fullWidth
          size="small"
          dropDownList={newList.map((item) => {
            return { ...item, name: item.fieldDisplayName };
          })}
          name="value"
          value={
            newList.find((item) => item.fieldColumnName === value)
              ?.fieldDisplayName
          }
          onchange={handleDropDown}
        />
      );
    }
    case 'blockPeriod': {
      const newList = [
        {
          id: '1',
          fieldDisplayName: '12',
          name: '12',
          fieldType: 'TXT',
          fieldDatatypeCode: 'NUM',
          fieldColumnName: '12',
        },
        {
          id: '2',
          fieldDisplayName: '24',
          name: '24',
          fieldType: 'TXT',
          fieldDatatypeCode: 'NUM',
          fieldColumnName: '24',
        },
      ];
      return (
        <ApiSelectTag
          defaultValue={'Select'}
          fullWidth
          size="small"
          dropDownList={newList}
          name="value"
          value={
            newList.find((item) => item.fieldColumnName === value)
              ?.fieldDisplayName
          }
          onchange={handleDropDown}
        />
      );
    }
    default: {
      return (
        <>
          <BenefitCalculationExpression
            handleFieldClick={handleFieldClick}
            handleBackClick={handleBackClick}
            handleCalculatorClick={handleCalculatorClick}
            handleFunctionClick={handleFunctionClick}
            expressionVal={value}
            fieldList={fieldList}
          />
        </>
      );
    }
  }
};

export default BenefitCommonExpressionEditor;
